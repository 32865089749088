<template>
    <div class="map">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- =========================== -->
        <b-container fluid class="base-color">
            <b-row align-h="center" class="bg-secondary">
                <b-col cols="6">
                    <b-form-datepicker class="m-1" v-model="targetDate" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :max="maxDate" @input="updateMapCoordinate()"></b-form-datepicker>
                </b-col>
                <b-col cols="5">
                    <b-form-select class="m-1" v-model="daySpanValue" :options="daySpanOptions" @change="updateMapCoordinate()"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="p-0">
                    <!-- =========================== -->
                    <l-map ref="map" :style="heightStyle()" :zoom="zoom" :minZoom="minZoom" :center="[center.lat, center.lng]" @update:zoom="zoomUpdated" @update:center="centerUpdated" :options="{ zoomControl: false }">
                        <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.name" :name="tileProvider.name" :visible="tileProvider.visible" :url="tileProvider.url" layer-type="base" :attribution="tileProvider.attribution" />
                        <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
                        <l-control-layers position="bottomright"></l-control-layers>
                        <l-control-zoom position="bottomleft"></l-control-zoom>
                        <l-control position="topleft">
                            <h4 class="border text-white px-4 rounded-pill shadow bg-warning">ほかくマップ</h4>
                        </l-control>
                        <l-control position="topright">
                            <b-button variant="light" size="sm" class="mb-2" aria-controls="sidebar-right" :aria-expanded="showSidebar ? 'true' : 'false'" @click="flipShowSidebar()">
                                <b-icon icon="gear-fill"></b-icon>
                            </b-button>
                        </l-control>
                        <l-control position="topright" v-show="false">
                            <b-button variant="light" @click="locateGps()"><b-icon icon="x-octagon"></b-icon></b-button>
                        </l-control>
                        <!-- ---------------- -->
                        <l-marker v-for="p in mapPoints" :key="'P' + p.id" :lat-lng="[p.lat, p.lng]" :icon="p.icon" :zIndexOffset="p.zIndexOffset">
                            <l-popup :options="p.popupOption">
                                <div>獣種：{{ p.db.species }} {{ p.db.gender }} {{ p.db.age }}</div>
                                <div>体長：{{ p.db.body_length }}</div>
                                <div>体高：{{ p.db.body_height }}</div>
                                <div>体重：{{ p.db.body_weight }}</div>
                                <div>個体番号：{{ p.db.lot_no }}</div>
                                <div>罠種：{{ p.db.trap_type }}</div>
                                <div>処理：{{ p.db.disposal }}</div>
                                <div>地区：{{ p.db.village }}</div>
                                <div>メモ：{{ p.db.title }}</div>
                                <div>投稿日：{{ p.db.posted_at }}</div>
                                <b-button variant="success" size="sm" class="m-2" v-b-modal.modalImageView @click="getImage(p.db.jpg_file)" v-show="p.db.jpg_file">
                                    <b-icon icon="card-image"></b-icon>
                                </b-button>
                            </l-popup>
                        </l-marker>
                        <!-- ---------------- -->
                        <l-marker v-for="p in mapTraps" :key="'T' + p.id" :lat-lng="[p.lat, p.lng]" :icon="p.icon" :options="p.markerOptions" :zIndexOffset="p.zIndexOffset">
                            <l-popup :options="p.popupOption">
                                <h4>{{ p.db.name }}</h4>
                                <div v-show="p.type == '子機'">罠状態：{{ p.db.led_capture ? "捕獲!" : "未捕獲" }}</div>
                                <div>バッテリー：{{ p.db.battery }} V</div>
                                <div>アライブ通信：{{ p.db.alived_at }}</div>
                            </l-popup>
                        </l-marker>
                        <!-- ---------------- -->
                        <l-circle v-for="p in mapCircles" :key="'C' + p.id" :lat-lng="[p.lat, p.lng]" :color="p.color" :radius="p.radius" :weight="p.weight" :opacity="p.opacity" :fillOpacity="p.fillOpacity" :fillColor="p.fillColor">
                            <l-popup>
                                <div>捕獲重点エリア</div>
                            </l-popup>
                        </l-circle>
                        <!-- ---------------- -->
                    </l-map>
                </b-col>
            </b-row>
        </b-container>
        <!-- =========================== -->
        <b-sidebar id="sidebar-right" ref="sidebar-right" title="表示設定" right shadow width="350px" backdrop v-model="showSidebar">
            <b-form class="text-left">
                <b-card class="m-2" header="表示項目">
                    <b-form-checkbox switch size="lg" v-model="confDispHokapato">ほかパト表示</b-form-checkbox>
                    <b-form-checkbox switch size="lg" v-model="confDispMarker">報告ピン表示</b-form-checkbox>
                    <b-form-checkbox switch size="lg" v-model="confDispHeat">報告ヒートマップ表示</b-form-checkbox>
                    <b-form-checkbox switch size="lg" v-model="confDispCircle">捕獲重点サークル表示</b-form-checkbox>
                </b-card>
                <div class="m-1 mb-5">
                    <b-button block variant="success" @click="submitSidebar()">OK</b-button>
                </div>
            </b-form>
        </b-sidebar>
        <!-- =========================== -->
        <b-modal id="modalImageView" size="xl" hide-footer>
            <b-aspect :aspect="aspect"><b-img fluid :src="modalImageView.jpgImg"/></b-aspect>
        </b-modal>
        <!-- =========================== -->
    </div>
</template>

<style>
/*
@import "https://cdn.jsdelivr.net/npm/semantic-ui@2.3.1/dist/semantic.min.css";
*/
</style>

<script>
import JWT from "jsonwebtoken";
//------------------------------------------
// リーフレットJS
//------------------------------------------
import "leaflet/dist/leaflet.css";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    markerColor: "orange",
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.3.1/dist/images/";
import { LMap, LTileLayer, LControlZoom, LControlLayers, LControlScale, LControl, LCircle, LMarker, LPopup } from "vue2-leaflet";
//--- ヒートマップ
import "leaflet.heat/dist/leaflet-heat.js";
//--- パルスアイコン
import "leaflet-pulse-icon/src/L.Icon.Pulse.js";
import "leaflet-pulse-icon/src/L.Icon.Pulse.css";
//--- エクストラマーカー
import "leaflet-extra-markers/dist/js/leaflet.extra-markers.min.js";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
//--- マーカーハイライト
import "leaflet.marker.highlight/dist/leaflet.marker.highlight.js";
import "leaflet.marker.highlight/dist/leaflet.marker.highlight.css";
//--- バウンスマーカー
import "@skyraptor/leaflet.bouncemarker/leaflet.bouncemarker.js";
//--- 色マーカー ---
import "leaflet-sprite/dist/leaflet.sprite.js";
//------------------------------------------

export default {
    name: "Map",
    components: {
        LMap,
        LTileLayer,
        LControlZoom,
        LControlLayers,
        LControlScale,
        LControl,
        LCircle,
        LMarker,
        LPopup
    },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSite: [],
            dbMyMapPoints: [],
            dbMapPoints: [],
            dbMapCircles: [],
            dbTraps: [],
            dbRepeaters: [],
            dbGateways: [],
            dbUser: {},
            allowTraps: [],
            mapPoints: [],
            mapTraps: [],
            mapCircles: [],
            //---------------------
            confDispHokapato: true,
            confDispMarker: true,
            confDispHeat: false,
            confDispCircle: true,
            //---------------------
            imgTrapGreen: require("@/assets/map-icon/trap-green.png"),
            imgTrapYellow: require("@/assets/map-icon/trap-yellow.png"),
            imgTrapRed: require("@/assets/map-icon/trap-red.png"),
            imgTrapGray: require("@/assets/map-icon/trap-gray.png"),
            //---------------------
            imgRepeaterGreen: require("@/assets/map-icon/repeater-green.png"),
            imgRepeaterYellow: require("@/assets/map-icon/repeater-yellow.png"),
            imgRepeaterRed: require("@/assets/map-icon/repeater-red.png"),
            imgRepeaterGray: require("@/assets/map-icon/repeater-gray.png"),
            //---------------------
            imgGatewayGreen: require("@/assets/map-icon/gateway-green.png"),
            imgGatewayYellow: require("@/assets/map-icon/gateway-yellow.png"),
            imgGatewayRed: require("@/assets/map-icon/gateway-red.png"),
            imgGatewayGray: require("@/assets/map-icon/gateway-gray.png"),
            //---------------------
            // imgPinRed: require("@/assets/pin-red.png"),
            // imgPinYellow: require("@/assets/pin-yellow.png"),
            // imgPinGreen: require("@/assets/pin-green.png"),
            // imgPinBlue: require("@/assets/pin-blue.png"),
            // imgPinGray: require("@/assets/pin-gray.png"),
            // imgPinBrown: require("@/assets/pin-brown.png"),
            // imgPinPurple: require("@/assets/pin-purple.png"),
            //---------------------
            minZoom: 6,
            zoom: 6, //11,
            center: { lat: 35, lng: 138.5 },
            //center: { lat: 32.680539, lng: 128.731613 }, //五島市デフォルト
            //---------------------
            uploadedImage: null,
            //---------------------
            showSidebar: false,
            //---------------------
            mapCoordinate: {
                center: { lat: 0, lng: 0 },
                zoom: 0,
                south: 0,
                north: 0,
                west: 0,
                east: 0
            },
            //---------------------
            heatmapData: null,
            heatmapObj: null,
            //---------------------
            pulseIconObj: null,
            //---------------------
            tileProviders: [
                {
                    name: "OpenStreet",
                    visible: false,
                    attribution: '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>',
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                },
                {
                    name: "標準地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
                },
                {
                    name: "衛星写真",
                    visible: true,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg"
                }
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
                // },
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                // }
            ],
            //---------------------
            targetDate: this.$moment().format("YYYY-MM-DD"),
            maxDate: this.$moment().format("YYYY-MM-DD"),
            daySpanValue: 1,
            daySpanOptions: [
                { text: "過去1日間", value: 1 },
                { text: "過去2日間", value: 2 },
                { text: "過去7日間", value: 7 },
                { text: "過去14日間", value: 14 },
                { text: "過去30日間", value: 30 },
                { text: "過去60日間", value: 60 },
                { text: "過去90日間", value: 90 },
                { text: "過去180日間", value: 180 },
                { text: "過去365日間", value: 365 }
            ],
            //---------------------
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            //---------------------
            modalImageView: {
                id: 0,
                name: "",
                jpgImg: "",
                date: ""
            },
            aspect: "16:9",
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("user-jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        //---------------------------------
        if (this.$localStorage.get("map.zoom")) this.zoom = Number(this.$localStorage.get("map.zoom"));
        if (this.$localStorage.get("map.lat")) this.center.lat = Number(this.$localStorage.get("map.lat"));
        if (this.$localStorage.get("map.lng")) this.center.lng = Number(this.$localStorage.get("map.lng"));
        //---------------------------------
        this.isLoading = true;
        await this.axiosGetSites();
        await this.axiosGetUsers();
        await this.axiosGetUserConfigs();
        await this.axiosGetTraps();
        await this.axiosGetRepeaters();
        await this.axiosGetGateways();
        //await new Promise(r => setTimeout(r, 100));
        this.updateMapCoordinate();
        this.isLoading = false;
        window.scrollTo(0, 0);
        //---------------------------------
    },
    //========================================================
    computed: {},
    //========================================================
    watch: {},
    //========================================================
    mounted: function() {
        window.addEventListener("resize", this.handleResize);
        //-------------------------------
        if (this.$localStorage.get("map.disp.hokapato")) this.confDispHokapato = Number(this.$localStorage.get("map.disp.hokapato")) ? true : false;
        if (this.$localStorage.get("map.disp.marker")) this.confDispMarker = Number(this.$localStorage.get("map.disp.marker")) ? true : false;
        if (this.$localStorage.get("map.disp.heat")) this.confDispHeat = Number(this.$localStorage.get("map.disp.heat")) ? true : false;
        if (this.$localStorage.get("map.disp.circle")) this.confDispCircle = Number(this.$localStorage.get("map.disp.circle")) ? true : false;
        //-------------------------------
        // URLクエリにGPS座標指定があれば
        if (this.$route.query.pid) {
            //const pid = this.$route.query.pid;
            this.center.lat = Number(this.$route.query.lat);
            this.center.lng = Number(this.$route.query.lng);
            this.zoom = 18;
        } else {
            if (this.$localStorage.get("map.zoom")) this.zoom = Number(this.$localStorage.get("map.zoom"));
            if (this.$localStorage.get("map.lat")) this.center.lat = Number(this.$localStorage.get("map.lat"));
            if (this.$localStorage.get("map.lng")) this.center.lng = Number(this.$localStorage.get("map.lng"));
            //-------------------------------
            // GPSから現在位置を取得
            //-------------------------------
            // if (navigator.geolocation) {
            //     navigator.geolocation.getCurrentPosition(
            //         function(position) {
            //             let coords = position.coords;
            //             // 緯度経度だけ取得
            //             this.center.lat = coords.latitude;
            //             this.center.lng = coords.longitude;
            //             this.zoom = 14; //Max=18
            //         }.bind(this),
            //         function(error) {
            //             self.console.log(error);
            //         }
            //     );
            // }
        }
    },
    //====================================================
    beforeDestroy: function() {
        window.removeEventListener("resize", this.handleResize);
    },
    //====================================================
    methods: {
        locateGps() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async function(position) {
                        let coords = position.coords;
                        // 緯度経度だけ取得
                        this.center.lat = coords.latitude;
                        this.center.lng = coords.longitude;
                        this.zoom = 18; //Max=18
                        const map = this.$refs.map.mapObject;
                        map.setZoom(this.zoom);
                        await new Promise(r => setTimeout(r, 100));
                        map.invalidateSize();
                    }.bind(this),
                    function(error) {
                        self.console.log(error);
                    }
                );
            }
        },
        //====================================================
        // サイドバー表示/非表示
        //====================================================
        flipShowSidebar() {
            this.showSidebar = !this.showSidebar;
        },
        //====================================================
        submitSidebar() {
            this.showSidebar = false;
            this.$localStorage.set("map.disp.hokapato", this.confDispHokapato ? 1 : 0);
            this.$localStorage.set("map.disp.marker", this.confDispMarker ? 1 : 0);
            this.$localStorage.set("map.disp.heat", this.confDispHeat ? 1 : 0);
            this.$localStorage.set("map.disp.circle", this.confDispCircle ? 1 : 0);
            this.updateMapCoordinate();
        },
        //====================================================
        zoomUpdated: function(zoom) {
            this.$localStorage.set("map.zoom", zoom);
            // if (zoom >= 7) {
            //     this.confDispHeat = false;
            //     this.confDispMarker = true;
            // } else {
            //     this.confDispHeat = true;
            //     this.confDispMarker = false;
            // }
        },
        centerUpdated: function(center) {
            this.$localStorage.set("map.lat", center.lat);
            this.$localStorage.set("map.lng", center.lng);
            //this.updateMapCoordinate();
            //------------岸さんAPP用-----------------
            const kLat = Number(center.lat);
            const kLng = Number(center.lng);
            const kZoom = Number(this.$localStorage.get("map.zoom"));
            //self.console.log(kLat + "|" + kLng + "|" + kZoom);
            window.parent.postMessage(JSON.stringify(kLat + "|" + kLng + "|" + kZoom), "*");
            //------------岸さんAPP用-----------------
        },
        updateMapCoordinate: async function() {
            //-----------------------------------
            function rnd(pt) {
                return Math.floor(pt * 1000000) / 1000000;
            }
            //-----------------------------------
            if (!this.jwt) return false;
            if (this.myInfo.exp < this.$moment().unix()) return false;
            //-----------------------------------
            const bounds = this.$refs.map.mapObject.getBounds();
            const zoom = this.$refs.map.mapObject.getZoom();
            this.mapCoordinate.zoom = zoom;
            this.mapCoordinate.center = rnd(bounds.getCenter());
            this.mapCoordinate.south = rnd(bounds.getSouth());
            this.mapCoordinate.north = rnd(bounds.getNorth());
            this.mapCoordinate.west = rnd(bounds.getWest());
            this.mapCoordinate.east = rnd(bounds.getEast());
            await this.axiosGetMapPoints();
            await this.axiosGetMapCircles();
            this.pointRegist(0);
        },
        //====================================================
        heightStyle: function() {
            let h = this.windowHeight;
            h = h - 62 - 45;
            if (h < 300) h = 300;
            return "height: " + h + "px;";
        },
        //====================================================
        handleResize: function() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        async axiosGetSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                params: {
                    _fields: "hunt_expires,latitude,longitude,zoom"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbSite = response.data.json[0];
                    this.daySpanValue = this.dbSite.hunt_expires;
                    //---------------------------------------------------------------
                    //--- ローカルストレージが使えない場合は、デフォルト座標に！
                    if (!this.$localStorage.get("map.lat")) this.center.lat = this.dbSite.latitude;
                    if (!this.$localStorage.get("map.lng")) this.center.lng = this.dbSite.longitude;
                    if (!this.$localStorage.get("map.zoom")) this.zoom = this.dbSite.zoom;
                    //---------------------------------------------------------------
                }
            });
        },
        //====================================================
        async axiosGetUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                params: {
                    _fields: "is_gw_view"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbUser = response.data.json[0];
                }
            });
        },
        //====================================================
        async axiosGetUserConfigs() {
            await this.axios({
                method: "GET",
                url: "/web/api/user_configs",
                params: {
                    _fields: "allow_view,trap_id"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.allowTraps = [];
                if (response.data.json) {
                    this.dbUserConfigs = response.data.json;
                    for (let i in this.dbUserConfigs) {
                        if (this.dbUserConfigs[i].allow_view == "1") {
                            this.allowTraps.push(this.dbUserConfigs[i].trap_id);
                        }
                    }
                }
            });
        },
        //====================================================
        // 子機
        //====================================================
        async axiosGetTraps() {
            //--------------------------------
            // 今日以外は、「ほかパト」の位置マーカーを表示しない。
            let diffDay = this.$moment(this.targetDate).diff(this.$moment(), "days");
            if (diffDay < 0) {
                this.dbTraps = [];
                return false;
            }
            //--------------------------------
            await this.axios({
                method: "GET",
                url: "/web/api/traps",
                params: {
                    "id[IN]": this.allowTraps.join(","),
                    is_active: "1"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbTraps = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbTraps) {
                    let alived_at = this.dbTraps[i].alived_at;
                    if (alived_at) {
                        let alived_at_format = this.$moment(alived_at).format("YYYY-MM-DD HH:mm");
                        this.dbTraps[i].alived_at = alived_at_format;
                    }
                }
                //--------------------------
            });
        },
        //====================================================
        // 中継機
        //====================================================
        async axiosGetRepeaters() {
            await this.axios({
                method: "GET",
                url: "/web/api/traps",
                params: {
                    //"id[IN]": this.allowTraps.join(","),
                    is_repeater: 1,
                    is_active: "1"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbRepeaters = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbRepeaters) {
                    let alived_at = this.dbRepeaters[i].alived_at;
                    if (alived_at) {
                        let alived_at_format = this.$moment(alived_at).format("YYYY-MM-DD HH:mm");
                        this.dbRepeaters[i].alived_at = alived_at_format;
                    }
                }
                //--------------------------
                // b-table用に ステータスを小細工
                for (let i in this.dbRepeaters) {
                    this.dbRepeaters[i].status = "green";
                    if (this.dbRepeaters[i].led_capture == 1) this.dbRepeaters[i].status = "red";
                    if (this.$moment().diff(this.$moment(this.dbRepeaters[i].alived_at), "days") >= 1) this.dbRepeaters[i].status = "yellow";
                    if (this.$moment().diff(this.$moment(this.dbRepeaters[i].alived_at), "days") >= 3) this.dbRepeaters[i].status = "gray";
                    if (!this.dbRepeaters[i].alived_at) this.dbRepeaters[i].status = "gray";
                }
                //--------------------------
            });
        },
        //====================================================
        // 親機
        //====================================================
        async axiosGetGateways() {
            await this.axios({
                method: "GET",
                url: "/web/api/gateways",
                params: {
                    is_active: "1"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbGateways = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbGateways) {
                    let alived_at = this.dbGateways[i].alived_at;
                    if (alived_at) {
                        let alived_at_format = this.$moment(alived_at).format("YYYY-MM-DD HH:mm");
                        this.dbGateways[i].alived_at = alived_at_format;
                    }
                }
                //--------------------------
                // b-table用に ステータスを小細工
                for (let i in this.dbGateways) {
                    this.dbGateways[i].status = "green";
                    if (this.$moment().diff(this.$moment(this.dbGateways[i].alived_at), "days") >= 1) this.dbGateways[i].status = "yellow";
                    if (this.$moment().diff(this.$moment(this.dbGateways[i].alived_at), "days") >= 3) this.dbGateways[i].status = "gray";
                    if (!this.dbGateways[i].alived_at) this.dbGateways[i].status = "gray";
                }
                //--------------------------
            });
        },

        //====================================================
        async axiosGetMapPoints() {
            //--------------------------------
            const branch_id = this.myInfo.branch_id;
            let sdays = (this.daySpanValue - 1) * -1;
            const sdate = this.$moment(this.targetDate)
                .add(sdays, "days")
                .format("YYYY-MM-DD");
            const edate = this.$moment(this.targetDate)
                .add(+1, "days")
                .format("YYYY-MM-DD");
            //--------------------------------
            const params = {
                status: "public",
                "branch_id[IN]": [0, branch_id].join(","),
                "posted_at[EQGREAT]": sdate,
                "posted_at[SMALL]": edate,
                //"gps_latitude[GREAT]": Number(this.mapCoordinate.south),
                //"gps_latitude[SMALL]": Number(this.mapCoordinate.north),
                //"gps_longitude[GREAT]": Number(this.mapCoordinate.west),
                //"gps_longitude[SMALL]": Number(this.mapCoordinate.east),
                "_order[id]": "desc"
            };
            await this.axios({
                method: "GET",
                url: "/web/api/map_hunt_points",
                params: params,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbMapPoints = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbMapPoints) {
                    let posted_at = this.dbMapPoints[i].posted_at;
                    if (posted_at) {
                        let posted_at_format = this.$moment(posted_at).format("YYYY-MM-DD");
                        this.dbMapPoints[i].posted_at = posted_at_format;
                    }
                }
                //--------------------------
            });
        },
        //====================================================
        async axiosGetMapCircles() {
            //--------------------------------
            let sdays = (this.daySpanValue - 1) * -1;
            const sdate = this.$moment(this.targetDate)
                .add(sdays, "days")
                .format("YYYY-MM-DD");
            const edate = this.$moment(this.targetDate)
                .add(+1, "days")
                .format("YYYY-MM-DD");
            //--------------------------------
            const params = {
                "published_at[EQGREAT]": sdate,
                "published_at[SMALL]": edate,
                //"gps_latitude[GREAT]": Number(this.mapCoordinate.south),
                //"gps_latitude[SMALL]": Number(this.mapCoordinate.north),
                //"gps_longitude[GREAT]": Number(this.mapCoordinate.west),
                //"gps_longitude[SMALL]": Number(this.mapCoordinate.east),
                "_order[id]": "desc"
            };
            await this.axios({
                method: "GET",
                url: "/web/api/map_hunt_circles",
                params: params,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbMapCircles = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbMapCircles) {
                    let published_at = this.dbMapCircles[i].published_at;
                    if (published_at) {
                        this.dbMapCircles[i].published_at = this.$moment(published_at).format("YYYY-MM-DD");
                    }
                    //--------------
                    let expired_at = this.dbMapCircles[i].expired_at;
                    if (expired_at) {
                        this.dbMapCircles[i].expired_at = this.$moment(expired_at).format("YYYY-MM-DD");
                    }
                }
                //--------------------------
            });
        },
        //====================================================
        // DBから取得したデータを、マップ変数に代入
        //====================================================
        pointRegist(highlight_id) {
            //--------------------------
            this.dispPinMap(highlight_id); // ピン
            this.dispTrapPinMap(); // ピン
            this.dispCircleMap(); // サークル
            this.showSidebar = false; // サイドバーを閉じる
        },
        //====================================================
        // 子機、中継機、親機のマーカーアイコン
        //====================================================
        dispTrapPinMap() {
            this.mapTraps = []; //クリア
            if (this.confDispHokapato == false) return true; //ほかパト非表示の場合
            //-----------------------------------------------
            // 子機
            //-----------------------------------------------
            for (let idx in this.dbTraps) {
                let rep = JSON.parse(JSON.stringify(this.dbTraps[idx]));
                let icon = null;
                //-------------------------------------
                icon = self.L.icon({ iconUrl: this.imgTrapGreen, iconSize: [25, 25] });
                if (rep.led_capture == "1") {
                    icon = self.L.icon({ iconUrl: this.imgTrapRed, iconSize: [25, 25] });
                }
                if (!rep.alived_at || this.$moment().diff(this.$moment(rep.alived_at), "days") >= 1) {
                    icon = self.L.icon({ iconUrl: this.imgTrapYellow, iconSize: [25, 25] });
                }
                if (!rep.alived_at || this.$moment().diff(this.$moment(rep.alived_at), "days") >= 3) {
                    icon = self.L.icon({ iconUrl: this.imgTrapGray, iconSize: [25, 25] });
                }
                if (icon) {
                    this.mapTraps.push({
                        id: rep.id,
                        type: "子機",
                        lat: Number(rep.gps_latitude),
                        lng: Number(rep.gps_longitude),
                        icon: icon,
                        markerOptions: { opacity: 1.0 },
                        popupOption: { minWidth: 50, offset: self.L.point([0, 3]) },
                        zIndexOffset: 200,
                        db: rep
                    });
                }
            }
            //-----------------------------------------------
            if (this.dbUser.is_gw_view == 1) {
                //-----------------------------------------------
                // 中継機
                //-----------------------------------------------
                for (let idx in this.dbRepeaters) {
                    let rep = JSON.parse(JSON.stringify(this.dbRepeaters[idx]));
                    let icon = null;
                    //-------------------------------------
                    icon = self.L.icon({ iconUrl: this.imgRepeaterGreen, iconSize: [30, 30] });
                    if (rep.led_capture == "1") {
                        icon = self.L.icon({ iconUrl: this.imgRepeaterRed, iconSize: [30, 30] });
                    }
                    if (!rep.alived_at || this.$moment().diff(this.$moment(rep.alived_at), "days") >= 1) {
                        icon = self.L.icon({ iconUrl: this.imgRepeaterYellow, iconSize: [30, 30] });
                    }
                    if (!rep.alived_at || this.$moment().diff(this.$moment(rep.alived_at), "days") >= 3) {
                        icon = self.L.icon({ iconUrl: this.imgRepeaterGray, iconSize: [30, 30] });
                    }
                    if (icon) {
                        this.mapTraps.push({
                            id: rep.id,
                            type: "中継機",
                            lat: Number(rep.gps_latitude),
                            lng: Number(rep.gps_longitude),
                            icon: icon,
                            markerOptions: { opacity: 1.0 },
                            popupOption: { minWidth: 50, offset: self.L.point([0, 3]) },
                            zIndexOffset: 200,
                            db: rep
                        });
                    }
                }
                //-----------------------------------------------
                // 親機
                //-----------------------------------------------
                for (let idx in this.dbGateways) {
                    let rep = JSON.parse(JSON.stringify(this.dbGateways[idx]));
                    let icon = null;
                    //-------------------------------------
                    icon = self.L.icon({ iconUrl: this.imgGatewayGreen, iconSize: [35, 35] });
                    if (rep.led_capture == "1") {
                        icon = self.L.icon({ iconUrl: this.imgGatewayRed, iconSize: [35, 35] });
                    }
                    if (!rep.alived_at || this.$moment().diff(this.$moment(rep.alived_at), "days") >= 1) {
                        icon = self.L.icon({ iconUrl: this.imgGatewayYellow, iconSize: [35, 35] });
                    }
                    if (!rep.alived_at || this.$moment().diff(this.$moment(rep.alived_at), "days") >= 3) {
                        icon = self.L.icon({ iconUrl: this.imgGatewayGray, iconSize: [35, 35] });
                    }
                    if (icon) {
                        this.mapTraps.push({
                            id: rep.id,
                            type: "親機",
                            lat: Number(rep.gps_latitude),
                            lng: Number(rep.gps_longitude),
                            icon: icon,
                            markerOptions: { opacity: 1.0 },
                            popupOption: { minWidth: 50, offset: self.L.point([0, 3]) },
                            zIndexOffset: 200,
                            db: rep
                        });
                    }
                }
            }
        },
        //====================================================
        // 捕獲投稿マーカーアイコン
        //====================================================
        dispPinMap(highlight_id) {
            //--------------------------
            // ピンマーカーデータ
            //--------------------------
            this.mapPoints = []; //クリア
            let highlightLat = 0;
            let highlightLng = 0;
            this.heatmapData = [];
            //--------------------------
            for (let idx in this.dbMapPoints) {
                let rep = JSON.parse(JSON.stringify(this.dbMapPoints[idx]));
                let id = rep.id;
                let lat = Number(rep.gps_latitude);
                let lng = Number(rep.gps_longitude);
                let status = rep.status;
                let user_id = rep.user_id;
                let icon = null;
                let options = null;
                const intensity = 1;
                //-------------------------------------
                if (highlight_id == rep.id) {
                    icon = self.L.spriteIcon("violet");
                } else if (status == "reject") {
                    icon = null; // リジェクトは表示しない
                } else if (status == "public") {
                    //if (rep.point_type == "目撃") icon = self.L.spriteIcon("blue");
                    //if (rep.point_type == "被害") icon = self.L.spriteIcon("red");
                    //if (rep.point_type == "捕獲") icon = self.L.spriteIcon("green");
                    icon = self.L.spriteIcon("orange");
                } else if (user_id == this.myInfo.id) {
                    icon = self.L.spriteIcon("purple"); // 投稿者のみ表示(審査待ち or 審査中)
                } else {
                    icon = self.L.spriteIcon("orange");
                }
                if (icon) {
                    //-------------------------------------
                    // ヒートマップ用データ
                    //-------------------------------------
                    if (this.confDispHeat) this.heatmapData.push([lat, lng, intensity]);
                    if (this.confDispMarker) {
                        //-------------------------------------
                        // ピンマーカー用データ
                        //-------------------------------------
                        this.mapPoints.push({
                            id: id,
                            lat: lat,
                            lng: lng,
                            icon: icon,
                            options: options,
                            popupOption: {
                                minWidth: 50,
                                offset: self.L.point([0, -23])
                            },
                            zIndexOffset: 300,
                            db: rep
                        });
                        //-------------------------------------
                    }
                }
            }
            //--------------------------
            if (highlightLat && highlightLng) this.dispPulseIcon(highlightLat, highlightLng);
            this.dispHeatmap(); // ヒートマップ
        },
        //====================================================
        // ヒートマップのプラグインは、
        // コンポーネント化されたNPMパッケージが無いため
        // 直接操作
        //====================================================
        dispHeatmap() {
            const heatmapOpt = {
                //gradient: { 0.5: "blue", 0.7: "lime", 1: "red" },
                minOpacity: 1.0,
                //maxZoom: 18,
                zIndexOffset: 500,
                max: 2.0,
                radius: 15,
                blur: 15
            };
            const map = this.$refs.map.mapObject;
            if (this.heatmapObj) {
                map.removeLayer(this.heatmapObj); //ヒートマップを一旦クリア
                this.heatmapObj = null;
            }
            this.heatmapObj = self.L.heatLayer(this.heatmapData, heatmapOpt).addTo(map);
        },
        //====================================================
        dispCircleMap() {
            //--------------------------
            // サークルデータ
            //--------------------------
            this.mapCircles = []; //クリア
            for (let idx in this.dbMapCircles) {
                let rep = JSON.parse(JSON.stringify(this.dbMapCircles[idx]));
                let lat = Number(rep.gps_latitude);
                let lng = Number(rep.gps_longitude);
                let color = "orange";
                let radius = rep.radius;
                let weight = 3;
                let opacity = 1;
                let fillColor = "orange";
                let fillOpacity = 0.1;

                if (this.confDispCircle && radius > 0 && opacity > 0) {
                    this.mapCircles.push({
                        id: rep.id,
                        lat: lat,
                        lng: lng,
                        radius: radius,
                        weight: weight,
                        color: color,
                        opacity: opacity,
                        fillColor: fillColor,
                        fillOpacity: fillOpacity,
                        db: rep
                    });
                }
            }
        },
        //====================================================
        // パルスアイコン
        //====================================================
        async dispPulseIcon(lat, lng) {
            await new Promise(r => setTimeout(r, 1000));
            const map = this.$refs.map.mapObject;
            //---------------------------------
            if (this.pulseIconObj) {
                map.removeLayer(this.pulseIconObj); //パルスアイコンを一旦クリア
                this.pulseIconObj = null;
            }
            //---------------------------------
            if (lat == 0 && lng == 0) return false;
            //---------------------------------
            //--- パルスアイコン ---
            //const pulsingIcon = self.L.icon.pulse({ iconSize: [12, 12], color: "red" });
            //this.pulseIconObj = self.L.marker([lat, lng], { icon: pulsingIcon }).addTo(map);
            //---------------------------------
            //--- マーカーハイライト ---
            //this.pulseIconObj = self.L.marker([lat, lng], { highlight: "permanent" }).addTo(map);
            //---------------------------------
            // バウンスマーカー
            this.pulseIconObj = self.L.marker([lat, lng], { bounceOnAdd: true }).addTo(map);
        },
        //====================================================
        async getImage(path) {
            this.modalImageView.jpgImg = "";
            //------------------------------------
            await this.axios({
                responseType: "blob",
                method: "GET",
                url: "/web/api/image/",
                params: { path: path },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(response => {
                    this.modalImageView.jpgImg = window.URL.createObjectURL(response.data);
                    this.modalImageView.date = response.headers["x-image-date"];
                })
                .catch(err => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        }
        //====================================================
    }
};
</script>
