<template>
    <div class="user">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <b-container fluid class="base-color">
            <div class="m-4"></div>
            <b-card>
                <b-form class="text-left">
                    <b-form-group label-size="lg" label="名前">
                        <b-form-input v-model="dbUser.name" :state="dbUser.name ? true : false" size="lg" readonly></b-form-input>
                    </b-form-group>
                    <b-form-group label-size="lg" label="アカウント">
                        <b-form-input v-model="dbUser.loginid" :state="dbUser.loginid ? true : false" size="lg" readonly></b-form-input>
                    </b-form-group>
                    <b-form-group label-size="lg" label="パスワード">
                        <b-form-input v-model="dbUser.loginpw" :state="dbUser.loginpw ? true : false" size="lg" type="password" readonly></b-form-input>
                    </b-form-group>
                    <b-form-group label-size="lg" label="メール">
                        <b-form-input v-model="dbUser.email" :state="true" size="lg" readonly></b-form-input>
                    </b-form-group>
                    <b-form-group label-size="lg" label="プッシュ通知">
                        <b-form-input v-model="dbUser.device_token" :state="true" size="lg" readonly></b-form-input>
                    </b-form-group>
                    <div class="mt-5" v-show="false">
                        <b-button block variant="success" @click="submitForm">確定</b-button>
                    </div>
                </b-form>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import JWT from "jsonwebtoken";
export default {
    name: "User",
    components: {},
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSite: [],
            dbUser: {},
            dispShot: false,
            dispHunt: false,
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("user-jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        //---------------------------------
        this.isLoading = true;
        await this.axiosGetSites();
        await this.axiosGetUsers();
        //await new Promise(r => setTimeout(r, 300));
        this.isLoading = false;
    },
    //========================================================
    computed: {},
    //========================================================
    watch: {},
    //========================================================
    mounted: function() {},
    //====================================================
    beforeDestroy: function() {},
    //====================================================
    methods: {
        //====================================================
        async axiosGetSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbSite = response.data.json[0];
                }
            });
        },
        //====================================================
        async axiosGetUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbUser = response.data.json[0];
                    if (this.dbUser.is_hunter) this.dispHunt = true;
                    if (this.dbUser.is_posting) this.dispShot = true;
                }
            });
        },
        //====================================================
        async submitForm() {
            this.$bvModal
                .msgBoxConfirm("変更します。よろしいですか？", {
                    headerBgVariant: "primary",
                    headerTextVariant: "light",
                    name: "確認",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "確定！",
                    cancelTitle: "Cancel",
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value == true) {
                        //-----------------------------------
                        const axiosData = {
                            name: this.dbUser.name.trim(),
                            loginid: this.dbUser.loginid.trim(),
                            loginpw: this.dbUser.loginpw.trim(),
                            email: this.dbUser.email.trim()
                        };
                        //-----------------------------------
                        this.axios({
                            method: "PUT",
                            url: "/web/api/users/" + this.dbUser.id,
                            headers: { Authorization: "Bearer " + this.jwt },
                            data: axiosData,
                            timeout: 30000
                        }).then(() => {
                            alert("変更しました。再度ログインしてください。");
                        });
                    }
                });
        }
        //====================================================
    }
};
</script>
