<template>
    <div class="map">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- =========================== -->
        <b-container fluid class="base-color">
            <b-row align-h="center" class="bg-secondary">
                <b-col cols="6">
                    <b-form-datepicker class="m-1" v-model="targetDate" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" :max="maxDate" @input="updateMapCoordinate()"></b-form-datepicker>
                </b-col>
                <b-col cols="5">
                    <b-form-select class="m-1" v-model="daySpanValue" :options="daySpanOptions" @change="updateMapCoordinate()"></b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="p-0">
                    <!-- =========================== -->
                    <l-map ref="map" :style="heightStyle()" :zoom="zoom" :minZoom="minZoom" :center="[center.lat, center.lng]" @update:zoom="zoomUpdated" @update:center="centerUpdated" :options="{ zoomControl: false }">
                        <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.name" :name="tileProvider.name" :visible="tileProvider.visible" :url="tileProvider.url" layer-type="base" :attribution="tileProvider.attribution" />
                        <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
                        <l-control-layers position="bottomright"></l-control-layers>
                        <l-control-zoom position="bottomleft"></l-control-zoom>
                        <l-control position="topleft">
                            <h4 class="border text-white px-4 rounded-pill shadow bg-info">もくげきマップ</h4>
                        </l-control>
                        <l-control position="topright">
                            <b-button variant="light" size="sm" class="mb-2" aria-controls="sidebar-right" :aria-expanded="showSidebar ? 'true' : 'false'" @click="flipShowSidebar()">
                                <b-icon icon="gear-fill"></b-icon>
                            </b-button>
                        </l-control>
                        <l-control position="topright" v-show="false">
                            <b-button variant="light" @click="locateGps()"><b-icon icon="x-octagon"></b-icon></b-button>
                        </l-control>
                        <l-marker v-for="p in mapPoints" :key="'P' + p.id" :lat-lng="[p.lat, p.lng]" :icon="p.icon" :zIndexOffset="p.zIndexOffset">
                            <l-popup :options="p.popupOption">
                                <div>投稿種別：{{ p.db.point_type }}</div>
                                <div>獣種：{{ p.db.species }}</div>
                                <div>頭数：{{ p.db.headcount }}</div>
                                <div v-show="questions[0]">{{ questions[0] }}：{{ p.db.qa1 }}</div>
                                <div v-show="questions[1]">{{ questions[1] }}：{{ p.db.qa2 }}</div>
                                <div v-show="questions[2]">{{ questions[2] }}：{{ p.db.qa3 }}</div>
                                <div v-show="questions[3]">{{ questions[3] }}：{{ p.db.qa4 }}</div>
                                <div v-show="questions[4]">{{ questions[4] }}：{{ p.db.qa5 }}</div>
                                <div>メモ：{{ p.db.title }}</div>
                                <div>投稿日：{{ p.db.posted_at }}</div>
                                <b-button variant="success" size="sm" class="m-2" v-b-modal.modalImageView @click="getImage(p.db.jpg_file)" v-show="p.db.jpg_file">
                                    <b-icon icon="card-image"></b-icon>
                                </b-button>
                            </l-popup>
                        </l-marker>
                        <!-- ---------------- -->
                        <l-circle v-for="p in mapCircles" :key="'C' + p.id" :lat-lng="[p.lat, p.lng]" :color="p.color" :radius="p.radius" :weight="p.weight" :opacity="p.opacity" :fillOpacity="p.fillOpacity" :fillColor="p.fillColor">
                            <l-popup>
                                <div>警戒エリア</div>
                            </l-popup>
                        </l-circle>
                        <!-- ---------------- -->
                    </l-map>
                </b-col>
            </b-row>
        </b-container>
        <!-- =========================== -->
        <b-sidebar id="sidebar-right" ref="sidebar-right" title="表示設定" right shadow width="300px" backdrop v-model="showSidebar">
            <b-form class="text-left">
                <b-card class="m-2" header="表示項目">
                    <b-form-checkbox switch size="lg" v-model="confDispHeat">ヒートマップ表示</b-form-checkbox>
                    <b-form-checkbox switch size="lg" v-model="confDispMarker">ピン表示</b-form-checkbox>
                    <b-form-checkbox switch size="lg" v-model="confDispCircle" v-show="false">警戒サークル表示</b-form-checkbox>
                </b-card>
                <b-card class="m-2" header="フィルター">
                    <b-form-select v-model="speciesSelected" :options="speciesOptions"></b-form-select>
                    <b-form-select v-model="headcountSelected" :options="headcountOptions"></b-form-select>
                    <!--
                    <b-form-select v-model="damageSelected" :options="damagesOptions"></b-form-select>
                    -->
                    <b-form-select v-model="qa1Selected" :options="qa1Options" v-show="questions[0]"></b-form-select>
                    <b-form-select v-model="qa2Selected" :options="qa2Options" v-show="questions[1]"></b-form-select>
                    <b-form-select v-model="qa3Selected" :options="qa3Options" v-show="questions[2]"></b-form-select>
                    <b-form-select v-model="qa4Selected" :options="qa4Options" v-show="questions[3]"></b-form-select>
                    <b-form-select v-model="qa5Selected" :options="qa5Options" v-show="questions[4]"></b-form-select>
                </b-card>
                <div class="m-2 mb-5">
                    <b-button block variant="success" @click="updateMapCoordinate()">OK</b-button>
                </div>
            </b-form>
        </b-sidebar>
        <!-- =========================== -->
        <b-modal id="modalImageView" size="xl" hide-footer>
            <b-aspect :aspect="aspect"><b-img fluid :src="modalImageView.jpgImg"/></b-aspect>
        </b-modal>
        <!-- =========================== -->
    </div>
</template>

<style>
table.b-table > thead > tr > th,
table.b-table > tbody > tr > td {
    white-space: nowrap;
    vertical-align: middle;
    padding: 3px;
}
</style>

<script>
import JWT from "jsonwebtoken";
//------------------------------------------
// リーフレットJS
//------------------------------------------
import "leaflet/dist/leaflet.css";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    markerColor: "orange",
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.3.1/dist/images/";
import { LMap, LTileLayer, LControlZoom, LControlLayers, LControlScale, LControl, LCircle, LMarker, LPopup } from "vue2-leaflet";
//--- ヒートマップ
import "leaflet.heat/dist/leaflet-heat.js";
//--- パルスアイコン
import "leaflet-pulse-icon/src/L.Icon.Pulse.js";
import "leaflet-pulse-icon/src/L.Icon.Pulse.css";
//--- マーカーハイライト
import "leaflet.marker.highlight/dist/leaflet.marker.highlight.js";
import "leaflet.marker.highlight/dist/leaflet.marker.highlight.css";
//--- バウンスマーカー
import "@skyraptor/leaflet.bouncemarker/leaflet.bouncemarker.js";
//--- 色マーカー ---
import "leaflet-sprite/dist/leaflet.sprite.js";
//------------------------------------------

export default {
    name: "Map",
    components: {
        LMap,
        LTileLayer,
        LControlZoom,
        LControlLayers,
        LControlScale,
        LControl,
        LCircle,
        LMarker,
        LPopup
    },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSite: [],
            dbMyMapPoints: [],
            dbMapPoints: [],
            dbMapCircles: [],
            dbQaLists: [],
            dbSpecies: [],
            dbDamages: [],
            dbHeadcounts: [],
            //---------------------
            mapPoints: [],
            mapCircles: [],
            //---------------------
            questions: ["", "", "", "", ""],
            qa1Options: [{ text: "---", value: "---" }],
            qa2Options: [{ text: "---", value: "---" }],
            qa3Options: [{ text: "---", value: "---" }],
            qa4Options: [{ text: "---", value: "---" }],
            qa5Options: [{ text: "---", value: "---" }],
            qa1Selected: "",
            qa2Selected: "",
            qa3Selected: "",
            qa4Selected: "",
            qa5Selected: "",
            //---------------------
            speciesOptions: [{ text: "---", value: "---" }],
            headcountOptions: [{ text: "---", value: "---" }],
            damagesOptions: [{ text: "--- 被害種別を選択してください ---", value: "---" }],
            speciesSelected: "",
            headcountSelected: "",
            damageSelected: "",
            //---------------------
            confDispHeat: true,
            confDispMarker: false,
            confDispCircle: false,
            confCircleColor: "#ff0000",
            //---------------------
            // imgPinRed: require("@/assets/pin-red.png"),
            // imgPinYellow: require("@/assets/pin-yellow.png"),
            // imgPinGreen: require("@/assets/pin-green.png"),
            // imgPinBlue: require("@/assets/pin-blue.png"),
            // imgPinGray: require("@/assets/pin-gray.png"),
            // imgPinBrown: require("@/assets/pin-brown.png"),
            // imgPinPurple: require("@/assets/pin-purple.png"),
            //---------------------
            minZoom: 6,
            zoom: 11,
            //center: { lat: 35, lng: 138.5 },
            center: { lat: 32.680539, lng: 128.731613 }, //五島市デフォルト
            //---------------------
            uploadedImage: null,
            //---------------------
            showSidebar: false,
            //---------------------
            mapCoordinate: {
                center: { lat: 0, lng: 0 },
                zoom: 0,
                south: 0,
                north: 0,
                west: 0,
                east: 0
            },
            //---------------------
            heatmapData: null,
            heatmapObj: null,
            //---------------------
            pulseIconObj: null,
            //---------------------
            tileProviders: [
                {
                    name: "OpenStreet",
                    visible: false,
                    attribution: '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>',
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                },
                {
                    name: "標準地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
                },
                {
                    name: "衛星写真",
                    visible: true,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg"
                }
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
                // },
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                // }
            ],
            //---------------------
            targetDate: this.$moment().format("YYYY-MM-DD"),
            maxDate: this.$moment().format("YYYY-MM-DD"),
            daySpanValue: 1,
            daySpanOptions: [
                { text: "過去1日間", value: 1 },
                { text: "過去2日間", value: 2 },
                { text: "過去7日間", value: 7 },
                { text: "過去14日間", value: 14 },
                { text: "過去30日間", value: 30 },
                { text: "過去60日間", value: 60 },
                { text: "過去90日間", value: 90 },
                { text: "過去180日間", value: 180 },
                { text: "過去365日間", value: 365 }
            ],
            //---------------------
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            //---------------------
            modalImageView: {
                id: 0,
                name: "",
                jpgImg: "",
                date: ""
            },
            aspect: "16:9",
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("user-jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        //---------------------------------
        if (this.$localStorage.get("map.zoom")) this.zoom = Number(this.$localStorage.get("map.zoom"));
        if (this.$localStorage.get("map.lat")) this.center.lat = Number(this.$localStorage.get("map.lat"));
        if (this.$localStorage.get("map.lng")) this.center.lng = Number(this.$localStorage.get("map.lng"));
        //---------------------------------
        this.isLoading = true;
        this.axiosGetSites();
        this.axiosGetQaLists();
        this.axiosGetSpecies();
        this.axiosGetDamages();
        this.axiosGetHeadcounts();
        await new Promise(r => setTimeout(r, 500));
        this.updateMapCoordinate();
        this.isLoading = false;
        window.scrollTo(0, 0);
        //---------------------------------
    },
    //========================================================
    computed: {},
    //========================================================
    watch: {},
    //========================================================
    mounted: function() {
        window.addEventListener("resize", this.handleResize);
        //-------------------------------
        // URLクエリにGPS座標指定があれば
        if (this.$route.query.pid) {
            //const pid = this.$route.query.pid;
            this.center.lat = Number(this.$route.query.lat);
            this.center.lng = Number(this.$route.query.lng);
            this.zoom = 18;
        } else {
            if (this.$localStorage.get("map.zoom")) this.zoom = Number(this.$localStorage.get("map.zoom"));
            if (this.$localStorage.get("map.lat")) this.center.lat = Number(this.$localStorage.get("map.lat"));
            if (this.$localStorage.get("map.lng")) this.center.lng = Number(this.$localStorage.get("map.lng"));
            //-------------------------------
            // GPSから現在位置を取得
            //-------------------------------
            // if (navigator.geolocation) {
            //     navigator.geolocation.getCurrentPosition(
            //         function(position) {
            //             let coords = position.coords;
            //             //self.console.log(coords);
            //             // 緯度経度だけ取得
            //             this.center.lat = coords.latitude;
            //             this.center.lng = coords.longitude;
            //             this.zoom = 14; //Max=18
            //         }.bind(this),
            //         function(error) {
            //             self.console.log(error);
            //         }
            //     );
            // }
        }
        //const map = this.$refs.map.mapObject;
        //map.invalidateSize();
        //-------------------------------
        //this.updateMapCoordinate();
    },
    //====================================================
    beforeDestroy: function() {
        window.removeEventListener("resize", this.handleResize);
    },
    //====================================================
    methods: {
        locateGps() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async function(position) {
                        let coords = position.coords;
                        // 緯度経度だけ取得
                        this.center.lat = coords.latitude;
                        this.center.lng = coords.longitude;
                        this.zoom = 18; //Max=18
                        const map = this.$refs.map.mapObject;
                        map.setZoom(this.zoom);
                        await new Promise(r => setTimeout(r, 100));
                        map.invalidateSize();
                    }.bind(this),
                    function(error) {
                        self.console.log(error);
                    }
                );
            }
        },
        //====================================================
        // サイドバー表示/非表示
        //====================================================
        flipShowSidebar() {
            this.showSidebar = !this.showSidebar;
        },
        //====================================================
        zoomUpdated: function(zoom) {
            this.$localStorage.set("map.zoom", zoom);
            // if (zoom >= 15) {
            //     this.confDispHeat = false;
            //     this.confDispMarker = true;
            // } else {
            //     this.confDispHeat = true;
            //     this.confDispMarker = false;
            // }
        },
        centerUpdated: function(center) {
            this.$localStorage.set("map.lat", center.lat);
            this.$localStorage.set("map.lng", center.lng);
            //self.console.log(center);
            //this.updateMapCoordinate();
            //------------岸さんAPP用-----------------
            const kLat = Number(center.lat);
            const kLng = Number(center.lng);
            const kZoom = Number(this.$localStorage.get("map.zoom"));
            //self.console.log(kLat + "|" + kLng + "|" + kZoom);
            window.parent.postMessage(JSON.stringify(kLat + "|" + kLng + "|" + kZoom), "*");
            //------------岸さんAPP用-----------------
        },
        updateMapCoordinate: async function() {
            //-----------------------------------
            function rnd(pt) {
                return Math.floor(pt * 1000000) / 1000000;
            }
            //-----------------------------------
            if (!this.jwt) return false;
            if (this.myInfo.exp < this.$moment().unix()) return false;
            //-----------------------------------
            const bounds = this.$refs.map.mapObject.getBounds();
            const zoom = this.$refs.map.mapObject.getZoom();
            this.mapCoordinate.zoom = zoom;
            this.mapCoordinate.center = rnd(bounds.getCenter());
            this.mapCoordinate.south = rnd(bounds.getSouth());
            this.mapCoordinate.north = rnd(bounds.getNorth());
            this.mapCoordinate.west = rnd(bounds.getWest());
            this.mapCoordinate.east = rnd(bounds.getEast());
            await this.axiosGetMapPoints();
            //await this.axiosGetMapCircles();
            this.pointRegist(0);
        },
        //====================================================
        heightStyle: function() {
            let h = this.windowHeight;
            h = h - 62 - 45;
            if (h < 300) h = 300;
            return "height: " + h + "px;";
        },
        //====================================================
        handleResize: function() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        async axiosGetSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                params: {
                    _fields: "post_expires,latitude,longitude,zoom"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbSite = response.data.json[0];
                    this.daySpanValue = this.dbSite.post_expires; //マップ表示日数
                    //---------------------------------------------------------------
                    //--- ローカルストレージが使えない場合は、デフォルト座標に！
                    if (!this.$localStorage.get("map.lat")) this.center.lat = this.dbSite.latitude;
                    if (!this.$localStorage.get("map.lng")) this.center.lng = this.dbSite.longitude;
                    if (!this.$localStorage.get("map.zoom")) this.zoom = this.dbSite.zoom;
                    //---------------------------------------------------------------
                }
            });
        },
        //====================================================
        async axiosGetMapPoints() {
            //--------------------------------
            let sdays = (this.daySpanValue - 1) * -1;
            const sdate = this.$moment(this.targetDate)
                .add(sdays, "days")
                .format("YYYY-MM-DD");
            const edate = this.$moment(this.targetDate)
                .add(+1, "days")
                .format("YYYY-MM-DD");
            //--------------------------------
            const params = {
                "posted_at[EQGREAT]": sdate,
                "posted_at[SMALL]": edate,
                // "gps_latitude[GREAT]": Number(this.mapCoordinate.south),
                // "gps_latitude[SMALL]": Number(this.mapCoordinate.north),
                // "gps_longitude[GREAT]": Number(this.mapCoordinate.west),
                // "gps_longitude[SMALL]": Number(this.mapCoordinate.east),
                "_order[id]": "desc"
            };
            await this.axios({
                method: "GET",
                url: "/web/api/map_points",
                params: params,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbMapPoints = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbMapPoints) {
                    let posted_at = this.dbMapPoints[i].posted_at;
                    if (posted_at) {
                        let posted_at_format = this.$moment(posted_at).format("YYYY-MM-DD");
                        this.dbMapPoints[i].posted_at = posted_at_format;
                    }
                }
                //--------------------------
            });
        },
        //====================================================
        // 警告サークルは、ポイントで賄えるため廃止
        //====================================================
        async axiosGetMapCircles() {
            //--------------------------------
            let sdays = (this.daySpanValue - 1) * -1;
            const sdate = this.$moment(this.targetDate)
                .add(sdays, "days")
                .format("YYYY-MM-DD");
            const edate = this.$moment(this.targetDate)
                .add(+1, "days")
                .format("YYYY-MM-DD");
            //--------------------------------
            const params = {
                "published_at[EQGREAT]": sdate,
                "published_at[SMALL]": edate,
                // "gps_latitude[GREAT]": Number(this.mapCoordinate.south),
                // "gps_latitude[SMALL]": Number(this.mapCoordinate.north),
                // "gps_longitude[GREAT]": Number(this.mapCoordinate.west),
                // "gps_longitude[SMALL]": Number(this.mapCoordinate.east),
                "_order[id]": "desc"
            };
            await this.axios({
                method: "GET",
                url: "/web/api/map_circles",
                params: params,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbMapCircles = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbMapCircles) {
                    let published_at = this.dbMapCircles[i].published_at;
                    if (published_at) {
                        this.dbMapCircles[i].published_at = this.$moment(published_at).format("YYYY-MM-DD");
                    }
                    //--------------
                    let expired_at = this.dbMapCircles[i].expired_at;
                    if (expired_at) {
                        this.dbMapCircles[i].expired_at = this.$moment(expired_at).format("YYYY-MM-DD");
                    }
                }
                //--------------------------
            });
        },
        //====================================================
        // QAリスト
        //====================================================
        async axiosGetQaLists() {
            await this.axios({
                method: "GET",
                url: "/web/api/qa_lists",
                params: {
                    "_order[question_no]": "asc",
                    "_order[answer_no]": "asc",
                    _fields: "qa_flg,question_no,name"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbQaLists = response.data.json;
                this.questions = ["", "", "", "", ""];
                this.qa1Options = [];
                this.qa2Options = [];
                this.qa3Options = [];
                this.qa4Options = [];
                this.qa5Options = [];
                for (let item of this.dbQaLists) {
                    if (item.qa_flg == "q") {
                        let question_no = item.question_no;
                        this.questions[question_no - 1] = item.name;
                        if (item.question_no == 1) this.qa1Options.push({ text: "--- " + item.name + " ---", value: "" });
                        if (item.question_no == 2) this.qa2Options.push({ text: "--- " + item.name + " ---", value: "" });
                        if (item.question_no == 3) this.qa3Options.push({ text: "--- " + item.name + " ---", value: "" });
                        if (item.question_no == 4) this.qa4Options.push({ text: "--- " + item.name + " ---", value: "" });
                        if (item.question_no == 5) this.qa5Options.push({ text: "--- " + item.name + " ---", value: "" });
                    }
                }
                for (let item of this.dbQaLists) {
                    if (item.qa_flg == "a") {
                        if (item.question_no == 1) this.qa1Options.push({ text: item.name, value: item.name });
                        if (item.question_no == 2) this.qa2Options.push({ text: item.name, value: item.name });
                        if (item.question_no == 3) this.qa3Options.push({ text: item.name, value: item.name });
                        if (item.question_no == 4) this.qa4Options.push({ text: item.name, value: item.name });
                        if (item.question_no == 5) this.qa5Options.push({ text: item.name, value: item.name });
                    }
                }
            });
        },
        //====================================================
        async axiosGetSpecies() {
            await this.axios({
                method: "GET",
                url: "/web/api/species",
                params: { "_order[sort_no]": "asc", _fields: "name" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbSpecies = response.data.json;
                this.speciesOptions = [];
                this.speciesOptions.push({ text: "--- 獣種 ---", value: "" });
                for (let species of this.dbSpecies) {
                    this.speciesOptions.push({ text: species.name, value: species.name });
                }
            });
        },
        //====================================================
        async axiosGetDamages() {
            await this.axios({
                method: "GET",
                url: "/web/api/damages",
                params: { "_order[sort_no]": "asc", _fields: "name" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbDamages = response.data.json;
                this.damagesOptions = [];
                this.damagesOptions.push({ text: "--- 被害種別 ---", value: "" });
                for (let item of this.dbDamages) {
                    this.damagesOptions.push({ text: item.name, value: item.name });
                }
            });
        },
        //====================================================
        async axiosGetHeadcounts() {
            await this.axios({
                method: "GET",
                url: "/web/api/headcounts",
                params: { "_order[sort_no]": "asc", _fields: "name" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbHeadcounts = response.data.json;
                this.headcountOptions = [];
                this.headcountOptions.push({ text: "--- 頭数 ---", value: "" });
                for (let item of this.dbHeadcounts) {
                    this.headcountOptions.push({ text: item.name, value: item.name });
                }
            });
        },
        //====================================================
        // DBから取得したデータを、マップ変数に代入
        //====================================================
        pointRegist(highlight_id) {
            //--------------------------
            this.dispPinMap(highlight_id); // ピン
            this.dispCircleMap(); // サークル
            this.showSidebar = false; // サイドバーを閉じる
        },
        //====================================================
        dispPinMap(highlight_id) {
            //--------------------------
            // ピンマーカーデータ
            //--------------------------
            this.mapPoints = []; //クリア
            let highlightLat = 0;
            let highlightLng = 0;
            this.heatmapData = [];
            //--------------------------
            for (let idx in this.dbMapPoints) {
                let rep = JSON.parse(JSON.stringify(this.dbMapPoints[idx]));
                if (this.speciesSelected && this.speciesSelected != rep.species) continue;
                if (this.headcountSelected && this.headcountSelected != rep.headcount) continue;
                if (this.damageSelected && this.damageSelected != rep.damage) continue;
                if (this.qa1Selected && this.qa1Selected != rep.qa1) continue;
                if (this.qa2Selected && this.qa2Selected != rep.qa2) continue;
                if (this.qa3Selected && this.qa3Selected != rep.qa3) continue;
                if (this.qa4Selected && this.qa4Selected != rep.qa4) continue;
                if (this.qa5Selected && this.qa5Selected != rep.qa5) continue;

                let id = rep.id;
                let lat = Number(rep.gps_latitude);
                let lng = Number(rep.gps_longitude);
                let status = rep.status;
                let user_id = rep.user_id;
                let icon = null;
                let options = null;
                const intensity = 1;
                //-------------------------------------
                if (highlight_id == rep.id) {
                    icon = self.L.spriteIcon("violet");
                } else if (status == "reject") {
                    icon = null; // リジェクトは表示しない
                } else if (status == "public") {
                    if (rep.point_type == "目撃") icon = self.L.spriteIcon("blue");
                    if (rep.point_type == "被害") icon = self.L.spriteIcon("red");
                    if (rep.point_type == "捕獲") icon = self.L.spriteIcon("green");
                    icon = self.L.spriteIcon("blue");
                } else if (user_id == this.myInfo.id) {
                    icon = self.L.spriteIcon("purple"); // 投稿者のみ表示(審査待ち or 審査中)
                } else {
                    icon = self.L.spriteIcon("orange");
                }
                if (icon) {
                    //-------------------------------------
                    // ヒートマップ用データ
                    //-------------------------------------
                    if (this.confDispHeat) {
                        this.heatmapData.push([lat, lng, intensity]);
                    }
                    //-------------------------------------
                    // ピンマーカー用データ
                    //-------------------------------------
                    if (this.confDispMarker) {
                        icon = self.L.spriteIcon("purple");
                        this.mapPoints.push({
                            id: id,
                            lat: lat,
                            lng: lng,
                            icon: icon,
                            options: options,
                            popupOption: {
                                minWidth: 50,
                                offset: self.L.point([0, -23])
                            },
                            zIndexOffset: 100,
                            db: rep
                        });
                        //-------------------------------------
                    }
                }
            }
            //--------------------------
            if (highlightLat && highlightLng) this.dispPulseIcon(highlightLat, highlightLng);
            this.dispHeatmap(); // ヒートマップ
        },
        //====================================================
        // ヒートマップのプラグインは、
        // コンポーネント化されたNPMパッケージが無いため
        // 直接操作
        //====================================================
        dispHeatmap() {
            const heatmapOpt = {
                //gradient: { 0.5: "blue", 0.7: "lime", 1: "red" },
                minOpacity: 1.0,
                //maxZoom: 18,
                max: 2.0,
                radius: 15,
                blur: 15
            };
            const map = this.$refs.map.mapObject;
            if (this.heatmapObj) {
                map.removeLayer(this.heatmapObj); //ヒートマップを一旦クリア
                this.heatmapObj = null;
            }
            this.heatmapObj = self.L.heatLayer(this.heatmapData, heatmapOpt).addTo(map);
        },
        //====================================================
        dispCircleMap() {
            //--------------------------
            // サークルデータ
            //--------------------------
            this.mapCircles = []; //クリア
            for (let idx in this.dbMapCircles) {
                let rep = JSON.parse(JSON.stringify(this.dbMapCircles[idx]));
                let lat = Number(rep.gps_latitude);
                let lng = Number(rep.gps_longitude);
                let color = "red";
                let radius = rep.radius;
                let weight = 3;
                let opacity = 1;
                let fillColor = "red";
                let fillOpacity = 0.1;
                // let dayDiff = this.$moment().diff(this.$moment(rep.posted_at), "days");
                // if (dayDiff > 3) {
                // }

                if (this.confDispCircle && radius > 0 && opacity > 0) {
                    this.mapCircles.push({
                        id: rep.id,
                        lat: lat,
                        lng: lng,
                        radius: radius,
                        weight: weight,
                        color: color,
                        opacity: opacity,
                        fillColor: fillColor,
                        fillOpacity: fillOpacity,
                        db: rep
                    });
                }
            }
        },
        //====================================================
        // パルスアイコン
        //====================================================
        async dispPulseIcon(lat, lng) {
            await new Promise(r => setTimeout(r, 1000));
            const map = this.$refs.map.mapObject;
            //---------------------------------
            if (this.pulseIconObj) {
                map.removeLayer(this.pulseIconObj); //パルスアイコンを一旦クリア
                this.pulseIconObj = null;
            }
            //---------------------------------
            if (lat == 0 && lng == 0) return false;
            //---------------------------------
            //--- パルスアイコン ---
            //const pulsingIcon = self.L.icon.pulse({ iconSize: [12, 12], color: "red" });
            //this.pulseIconObj = self.L.marker([lat, lng], { icon: pulsingIcon }).addTo(map);
            //---------------------------------
            //--- マーカーハイライト ---
            //this.pulseIconObj = self.L.marker([lat, lng], { highlight: "permanent" }).addTo(map);
            //---------------------------------
            // バウンスマーカー
            this.pulseIconObj = self.L.marker([lat, lng], { bounceOnAdd: true }).addTo(map);
        },
        //====================================================
        async getImage(path) {
            this.modalImageView.jpgImg = "";
            //------------------------------------
            await this.axios({
                responseType: "blob",
                method: "GET",
                url: "/web/api/image/",
                params: { path: path },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(response => {
                    this.modalImageView.jpgImg = window.URL.createObjectURL(response.data);
                    this.modalImageView.date = response.headers["x-image-date"];
                })
                .catch(err => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        }
        //====================================================
    }
};
// alter table map_points add column status varchar(10) default 'new' comment 'new, research, public, reject' after user_name ;

/*
------------------------------------
■背景
・公開は即時性が必要。土日をまたいで公開では遅すぎ。
・市の職員は、毎日メンテしない。
・

■対策案
・投稿でサークル追加(デフォルトで、半径100mでOK?)
・サークルの有効期限を自動化

■方法
・投稿の密度 ---> ヒートマップ
・投稿の新鮮度 ---> サークルの大きさと濃さ
・危険度
------------------------------------
 */
</script>
