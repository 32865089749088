<template>
    <div class="notified-list">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- =========================== -->
        <div class="m-3 text-left">
            <b-row>
                <b-col>
                    <h4 class="border px-4 rounded-pill shadow bg-warning d-inline">通知一覧</h4>
                </b-col>
                <b-col class="text-right">
                    <b-button to="/notify-config" variant="info">
                        <b-icon icon="gear-fill" class="mx-3"></b-icon>
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <!-- =========================== -->
        <b-container fluid>
            <div v-for="item in dbPushLogs" :key="item.id" class="my-3 text-left shadow">
                <b-card :header="item.title" style="background-color: #e1e5ee;">
                    <h6>{{ item.body }}</h6>
                    <h6 class="text-right">
                        <small>{{ item.modified_at }}</small>
                    </h6>
                </b-card>
            </div>
        </b-container>
        <div class="m-5 p-5"></div>
    </div>
</template>

<script>
import JWT from "jsonwebtoken";
export default {
    name: "NotifiedList",
    components: {},
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: {},
            dbSite: {},
            dbPushLogs: [],
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("user-jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        //---------------------------------
        this.isLoading = true;
        await this.axiosGetSites();
        await this.axiosGetPushLogs();
        this.isLoading = false;
        window.scrollTo(0, 0);
    },
    //========================================================
    computed: {},
    //========================================================
    watch: {},
    //========================================================
    mounted: function() {},
    //====================================================
    beforeDestroy: function() {},
    //====================================================
    methods: {
        //====================================================
        async axiosGetSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbSite = response.data.json[0];
                }
            });
        },
        //====================================================
        async axiosGetPushLogs() {
            const stime = this.$moment()
                .add(-30, "days") //過去30日分のPUSH通知ログ
                .format("YYYY-MM-DD");
            //------------------------------
            const axiosParams = {
                user_id: this.myInfo.id,
                "modified_at[GREAT]": stime,
                is_sent: "1",
                "_order[id]": "desc",
                _limit: 100,
                _fields: "id,title,body,modified_at"
            };
            //------------------------------
            await this.axios({
                method: "GET",
                url: "/web/api/push_logs",
                headers: { Authorization: "Bearer " + this.jwt },
                params: axiosParams,
                timeout: 30000
            }).then(response => {
                if (response.data.json) {
                    this.dbPushLogs = response.data.json;
                    // b-table用に 日付フォーマットを小細工
                    for (let i in this.dbPushLogs) {
                        let dt = this.dbPushLogs[i].modified_at;
                        if (dt) {
                            let dt_format = this.$moment(dt).format("YYYY-MM-DD HH:mm");
                            this.dbPushLogs[i].modified_at = dt_format;
                        }
                    }
                }
            });
            //------------------------------
        }
        //====================================================
    }
};
</script>
