<template>
    <div class="hokapato-list">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- =========================== -->
        <div class="m-3 text-left">
            <h4 class="border px-4 rounded-pill shadow bg-warning d-inline">ほかパトリスト</h4>
        </div>
        <!-- =========================== -->
        <b-container fluid>
            <div>
                <h4>子機</h4>
                <div>
                    <b-img :src="imgTrapGreen" width="20"></b-img>
                    <small>稼働中 </small>
                    <b-img :src="imgTrapRed" width="20"></b-img>
                    <small>捕獲 </small>
                    <b-img :src="imgTrapYellow" width="20"></b-img>
                    <small>異常(1日異常不通) </small>
                    <b-img :src="imgTrapGray" width="20"></b-img>
                    <small>休止中(3日以上不通) </small>
                </div>
                <div class="shadow">
                    <b-table :items="dbTraps" :fields="trapFields" responsive outlined head-variant="dark">
                        <template #cell(name)="data">
                            <span v-if="data.item.alived_at">
                                <b-link @click="setLatLng(data.item.gps_latitude, data.item.gps_longitude, data.item.name)">{{ data.item.name }}</b-link>
                            </span>
                            <span v-if="!data.item.alived_at">
                                {{ data.item.name }}
                            </span>
                        </template>
                        <template #cell(status)="data">
                            <b-img v-show="data.item.status == 'green'" :src="imgTrapGreen" width="20"></b-img>
                            <b-img v-show="data.item.status == 'red'" :src="imgTrapRed" width="20"></b-img>
                            <b-img v-show="data.item.status == 'yellow'" :src="imgTrapYellow" width="20"></b-img>
                            <b-img v-show="data.item.status == 'gray'" :src="imgTrapGray" width="20"></b-img>
                        </template>
                        <template #cell(battery)="data">
                            <b-progress :value="data.item.battery" :max="9.0" :precision="1" show-value height="2rem" variant="info"></b-progress>
                        </template>
                        <template #cell(alived_at)="data">
                            <b-link @click="showTrapLogs(data.item.id, data.item.name)">
                                {{ data.item.alived_at ? $moment(data.item.alived_at).format("MM-DD HH:mm") : "" }}
                            </b-link>
                        </template>
                    </b-table>
                </div>
            </div>
            <div v-show="dbUser.is_gw_view">
                <h4>中継機</h4>
                <div>
                    <b-img :src="imgRepeaterGreen" width="20"></b-img>
                    <small>稼働中 </small>
                    <b-img :src="imgRepeaterYellow" width="20"></b-img>
                    <small>異常(1日異常不通) </small>
                    <b-img :src="imgRepeaterGray" width="20"></b-img>
                    <small>休止中(3日以上不通) </small>
                </div>
                <div class="shadow">
                    <b-table :items="dbRepeaters" :fields="repeaterFields" responsive outlined head-variant="dark">
                        <template #cell(name)="data">
                            <span v-if="data.item.alived_at">
                                <b-link @click="setLatLng(data.item.gps_latitude, data.item.gps_longitude, data.item.name)">{{ data.item.name }}</b-link>
                            </span>
                            <span v-if="!data.item.alived_at">
                                {{ data.item.name }}
                            </span>
                        </template>
                        <template #cell(status)="data">
                            <b-img v-show="data.item.status == 'green'" :src="imgRepeaterGreen" width="20"></b-img>
                            <b-img v-show="data.item.status == 'red'" :src="imgRepeaterRed" width="20"></b-img>
                            <b-img v-show="data.item.status == 'yellow'" :src="imgRepeaterYellow" width="20"></b-img>
                            <b-img v-show="data.item.status == 'gray'" :src="imgRepeaterGray" width="20"></b-img>
                        </template>
                        <template #cell(battery)="data">
                            <b-progress :value="data.item.battery" :max="9.0" :precision="1" show-value height="2rem" variant="info"></b-progress>
                        </template>
                        <template #cell(alived_at)="data">
                            <b-link @click="showRepeaterLogs(data.item.id, data.item.name)">
                                {{ data.item.alived_at ? $moment(data.item.alived_at).format("MM-DD HH:mm") : "" }}
                            </b-link>
                        </template>
                    </b-table>
                </div>
            </div>
            <div v-show="dbUser.is_gw_view">
                <h4>親機</h4>
                <div>
                    <b-img :src="imgGatewayGreen" width="20"></b-img>
                    <small>稼働中 </small>
                    <b-img :src="imgGatewayYellow" width="20"></b-img>
                    <small>異常(1日異常不通) </small>
                    <b-img :src="imgGatewayGray" width="20"></b-img>
                    <small>休止中(3日以上不通) </small>
                </div>
                <div class="shadow">
                    <b-table :items="dbGateways" :fields="gatewayFields" responsive outlined head-variant="dark">
                        <template #cell(name)="data">
                            <span v-if="data.item.alived_at">
                                <b-link @click="setLatLng(data.item.gps_latitude, data.item.gps_longitude, data.item.name)">{{ data.item.name }}</b-link>
                            </span>
                            <span v-if="!data.item.alived_at">
                                {{ data.item.name }}
                            </span>
                        </template>
                        <template #cell(status)="data">
                            <b-img v-show="data.item.status == 'green'" :src="imgGatewayGreen" width="20"></b-img>
                            <b-img v-show="data.item.status == 'red'" :src="imgGatewayRed" width="20"></b-img>
                            <b-img v-show="data.item.status == 'yellow'" :src="imgGatewayYellow" width="20"></b-img>
                            <b-img v-show="data.item.status == 'gray'" :src="imgGatewayGray" width="20"></b-img>
                        </template>
                        <template #cell(battery)="data">
                            <b-progress :value="data.item.battery" :max="12.0" :precision="1" show-value height="2rem" variant="info"></b-progress>
                        </template>
                        <template #cell(alived_at)="data">
                            <b-link @click="showGatewayLogs(data.item.id, data.item.name)">
                                {{ data.item.alived_at ? $moment(data.item.alived_at).format("MM-DD HH:mm") : "" }}
                            </b-link>
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="m-5 p-5"></div>
        </b-container>
        <!-- =========================== -->
        <b-modal ref="trap-log-modal" :title="modalTitleTrap" scrollable size="xl" ok-only>
            <div class="text-center">
                <b-table :items="dbTrapLogs" :fields="trapLogFields" responsive outlined bordered head-variant="dark"></b-table>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="success" class="float-right" @click="downloadTrapLogsCSV()">CSV</b-button>
                </div>
            </template>
        </b-modal>
        <!-- =========================== -->
        <b-modal ref="gateway-log-modal" :title="modalTitleGateway" scrollable size="xl" ok-only>
            <div class="text-center">
                <b-table :items="dbGatewayLogs" :fields="gatewayLogFields" responsive outlined bordered head-variant="dark"></b-table>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="success" class="float-right" @click="downloadGatewayLogsCSV()">CSV</b-button>
                </div>
            </template>
        </b-modal>
        <!-- =========================== -->
        <b-modal id="modalMapView" ref="modalMapView" :title="modalTitleMap" size="xl" ok-only>
            <l-map ref="mapView" style="height: 70vh;" :zoom="zoom" :minZoom="minZoom" :center="[center.lat, center.lng]" :options="{ zoomControl: false }">
                <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.name" :name="tileProvider.name" :visible="tileProvider.visible" :url="tileProvider.url" layer-type="base" :attribution="tileProvider.attribution" />
                <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
                <l-control-layers position="bottomright"></l-control-layers>
                <l-control-zoom position="bottomleft"></l-control-zoom>
                <l-marker :lat-lng="[center.lat, center.lng]"></l-marker>
            </l-map>
        </b-modal>
        <!-- =========================== -->
    </div>
</template>

<script>
import JWT from "jsonwebtoken";
//------------------------------------------
// リーフレットJS
//------------------------------------------
import "leaflet/dist/leaflet.css";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    markerColor: "orange",
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.3.1/dist/images/";
import { LMap, LTileLayer, LControlZoom, LControlLayers, LControlScale, LMarker } from "vue2-leaflet";
//------------------------------------------

export default {
    name: "HokapatoList",
    components: {
        LMap,
        LTileLayer,
        LControlZoom,
        LControlLayers,
        LControlScale,
        LMarker
    },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSite: [],
            dbUser: {},
            dbUserConfigs: [],
            allowTraps: [],
            dbTraps: [],
            dbRepeaters: [],
            dbGateways: [],
            dbTrapLogs: [],
            dbRepeaterLogs: [],
            dbGatewayLogs: [],
            modalTitleTrap: "子機ログ",
            modalTitleRepeater: "中継機ログ",
            modalTitleGateway: "親機ログ",
            modalTitleMap: "マップ",
            //---------------------
            imgTrapGreen: require("@/assets/map-icon/trap-green.png"),
            imgTrapYellow: require("@/assets/map-icon/trap-yellow.png"),
            imgTrapRed: require("@/assets/map-icon/trap-red.png"),
            imgTrapGray: require("@/assets/map-icon/trap-gray.png"),
            //---------------------
            imgRepeaterGreen: require("@/assets/map-icon/repeater-green.png"),
            imgRepeaterYellow: require("@/assets/map-icon/repeater-yellow.png"),
            imgRepeaterRed: require("@/assets/map-icon/repeater-red.png"),
            imgRepeaterGray: require("@/assets/map-icon/repeater-gray.png"),
            //---------------------
            imgGatewayGreen: require("@/assets/map-icon/gateway-green.png"),
            imgGatewayYellow: require("@/assets/map-icon/gateway-yellow.png"),
            imgGatewayRed: require("@/assets/map-icon/gateway-red.png"),
            imgGatewayGray: require("@/assets/map-icon/gateway-gray.png"),
            //---------------------
            trapFields: [
                { key: "name", label: "子機", sortable: true },
                { key: "status", label: "状態", sortable: true },
                { key: "battery", label: "バッテリ", sortable: true },
                { key: "alived_at", label: "アライブ", sortable: true }
            ],
            repeaterFields: [
                { key: "name", label: "中継機", sortable: true },
                { key: "status", label: "状態", sortable: true },
                { key: "battery", label: "バッテリ", sortable: true },
                { key: "alived_at", label: "アライブ", sortable: true }
            ],
            gatewayFields: [
                { key: "name", label: "親機", sortable: true },
                { key: "status", label: "状態", sortable: true },
                { key: "battery", label: "バッテリ電圧", sortable: true },
                { key: "alived_at", label: "アライブ", sortable: true }
            ],
            trapLogFields: [
                { key: "modified_at", label: "通信日時", sortable: true },
                { key: "lora_serial", label: "子機LoRa", sortable: true },
                { key: "triggered", label: "イベント", sortable: true },
                { key: "battery_volt", label: "バッテリ電圧", sortable: true },
                { key: "gw_lora_serial", label: "親機LoRa", sortable: true },
                { key: "trap_rssi", label: "無線電波強度", sortable: true },
                { key: "ra_rssi", label: "環境ノイズ", sortable: true },
                { key: "gps_latitude", label: "GPS緯度", sortable: true },
                { key: "gps_longitude", label: "GPS経度", sortable: true },
                { key: "time_code", label: "タイムコード", sortable: true },
                { key: "raw_data", label: "data", sortable: false }
            ],
            repeaterLogFields: [
                { key: "modified_at", label: "通信日時", sortable: true },
                { key: "lora_serial", label: "子機LoRa", sortable: true },
                { key: "triggered", label: "イベント", sortable: true },
                { key: "battery_volt", label: "バッテリ電圧", sortable: true },
                { key: "gw_lora_serial", label: "親機LoRa", sortable: true },
                { key: "trap_rssi", label: "無線電波強度", sortable: true },
                { key: "ra_rssi", label: "環境ノイズ", sortable: true },
                { key: "gps_latitude", label: "GPS緯度", sortable: true },
                { key: "gps_longitude", label: "GPS経度", sortable: true },
                { key: "time_code", label: "タイムコード", sortable: true },
                { key: "raw_data", label: "data", sortable: false }
            ],
            gatewayLogFields: [
                { key: "modified_at", label: "通信日時", sortable: true },
                { key: "lora_serial", label: "親機LoRa", sortable: true },
                { key: "battery", label: "バッテリ電圧", sortable: true },
                { key: "lte_rssi", label: "モバイル感度", sortable: true },
                { key: "gps_latitude", label: "GPS緯度", sortable: true },
                { key: "gps_longitude", label: "GPS経度", sortable: true }
            ],
            //---------------------
            minZoom: 6,
            zoom: 18,
            center: { lat: 35.681, lng: 139.767 },
            //---------------------
            tileProviders: [
                {
                    name: "OpenStreet",
                    visible: false,
                    attribution: '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>',
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                },
                {
                    name: "標準地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
                },
                {
                    name: "衛星写真",
                    visible: true,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg"
                }
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
                // },
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                // }
            ],
            //---------------------
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("user-jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        //---------------------------------
        this.isLoading = true;
        await this.axiosGetSites();
        await this.axiosGetUsers();
        await this.axiosGetUserConfigs();
        //await new Promise(r => setTimeout(r, 300));
        await this.axiosGetTraps();
        await this.axiosGetRepeaters();
        await this.axiosGetGateways();
        this.isLoading = false;
        window.scrollTo(0, 0);
    },
    //========================================================
    computed: {},
    //========================================================
    watch: {},
    //========================================================
    mounted: function() {},
    //====================================================
    beforeDestroy: function() {},
    //====================================================
    methods: {
        //====================================================
        async axiosGetSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbSite = response.data.json[0];
                }
            });
        },
        //====================================================
        async axiosGetUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                params: {
                    _fields: "is_gw_view"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbUser = response.data.json[0];
                }
            });
        },
        //====================================================
        async axiosGetUserConfigs() {
            await this.axios({
                method: "GET",
                url: "/web/api/user_configs",
                params: {
                    _fields: "allow_view,trap_id"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.allowTraps = [];
                if (response.data.json) {
                    this.dbUserConfigs = response.data.json;
                    for (let i in this.dbUserConfigs) {
                        if (this.dbUserConfigs[i].allow_view == "1") {
                            this.allowTraps.push(this.dbUserConfigs[i].trap_id);
                        }
                    }
                }
            });
        },
        //====================================================
        // 子機
        //====================================================
        async axiosGetTraps() {
            await this.axios({
                method: "GET",
                url: "/web/api/traps",
                params: {
                    "id[IN]": this.allowTraps.join(","),
                    is_repeater: 0,
                    is_active: "1",
                    _fields: "id,name,battery,alived_at,led_capture,gps_latitude,gps_longitude"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbTraps = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbTraps) {
                    let alived_at = this.dbTraps[i].alived_at;
                    if (alived_at) {
                        let alived_at_format = this.$moment(alived_at).format("YYYY-MM-DD HH:mm");
                        this.dbTraps[i].alived_at = alived_at_format;
                    }
                }
                //--------------------------
                // b-table用に ステータスを小細工
                for (let i in this.dbTraps) {
                    this.dbTraps[i].status = "green";
                    if (this.dbTraps[i].led_capture == 1) this.dbTraps[i].status = "red";
                    if (this.$moment().diff(this.$moment(this.dbTraps[i].alived_at), "days") >= 1) this.dbTraps[i].status = "yellow";
                    if (this.$moment().diff(this.$moment(this.dbTraps[i].alived_at), "days") >= 3) this.dbTraps[i].status = "gray";
                    if (!this.dbTraps[i].alived_at) this.dbTraps[i].status = "gray";
                }
                //--------------------------
            });
        },
        //====================================================
        // 中継機
        //====================================================
        async axiosGetRepeaters() {
            await this.axios({
                method: "GET",
                url: "/web/api/traps",
                params: {
                    //"id[IN]": this.allowTraps.join(","),
                    is_repeater: 1,
                    is_active: "1",
                    _fields: "id,name,battery,alived_at,led_capture,gps_latitude,gps_longitude"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbRepeaters = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbRepeaters) {
                    let alived_at = this.dbRepeaters[i].alived_at;
                    if (alived_at) {
                        let alived_at_format = this.$moment(alived_at).format("YYYY-MM-DD HH:mm");
                        this.dbRepeaters[i].alived_at = alived_at_format;
                    }
                }
                //--------------------------
                // b-table用に ステータスを小細工
                for (let i in this.dbRepeaters) {
                    this.dbRepeaters[i].status = "green";
                    if (this.dbRepeaters[i].led_capture == 1) this.dbRepeaters[i].status = "red";
                    if (this.$moment().diff(this.$moment(this.dbRepeaters[i].alived_at), "days") >= 1) this.dbRepeaters[i].status = "yellow";
                    if (this.$moment().diff(this.$moment(this.dbRepeaters[i].alived_at), "days") >= 3) this.dbRepeaters[i].status = "gray";
                    if (!this.dbRepeaters[i].alived_at) this.dbRepeaters[i].status = "gray";
                }
                //--------------------------
            });
        },
        //====================================================
        // 親機
        //====================================================
        async axiosGetGateways() {
            await this.axios({
                method: "GET",
                url: "/web/api/gateways",
                params: {
                    is_active: "1",
                    _fields: "id,name,battery,alived_at,gps_latitude,gps_longitude"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbGateways = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbGateways) {
                    let alived_at = this.dbGateways[i].alived_at;
                    if (alived_at) {
                        let alived_at_format = this.$moment(alived_at).format("YYYY-MM-DD HH:mm");
                        this.dbGateways[i].alived_at = alived_at_format;
                    }
                }
                //--------------------------
                // b-table用に ステータスを小細工
                for (let i in this.dbGateways) {
                    this.dbGateways[i].status = "green";
                    if (this.$moment().diff(this.$moment(this.dbGateways[i].alived_at), "days") >= 1) this.dbGateways[i].status = "yellow";
                    if (this.$moment().diff(this.$moment(this.dbGateways[i].alived_at), "days") >= 3) this.dbGateways[i].status = "gray";
                    if (!this.dbGateways[i].alived_at) this.dbGateways[i].status = "gray";
                }
                //--------------------------
            });
        },
        //====================================================
        // 子機ログ
        //====================================================
        async axiosGetTrapLogs(trap_id) {
            this.dbTrapLogs = [];
            await this.axios({
                method: "GET",
                url: "/web/api/trap_logs",
                params: {
                    trap_id: trap_id,
                    "_order[id]": "desc",
                    _limit: 300
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbTrapLogs = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbTrapLogs) {
                    let dt = this.dbTrapLogs[i].modified_at;
                    if (dt) {
                        let dt_format = this.$moment(dt).format("YYYY-MM-DD HH:mm");
                        this.dbTrapLogs[i].modified_at = dt_format;
                    }
                }
                //--------------------------
            });
        },
        //====================================================
        // 親機ログ
        //====================================================
        async axiosGetGatewayLogs(gateway_id) {
            this.dbGatewayLogs = [];
            await this.axios({
                method: "GET",
                url: "/web/api/gateway_logs",
                params: {
                    gateway_id: gateway_id,
                    "_order[id]": "desc",
                    _limit: 300,
                    _fields: "modified_at,lora_serial,battery,lte_rssi,gps_latitude,gps_longitude"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbGatewayLogs = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbGatewayLogs) {
                    let dt = this.dbGatewayLogs[i].modified_at;
                    if (dt) {
                        let dt_format = this.$moment(dt).format("YYYY-MM-DD HH:mm");
                        this.dbGatewayLogs[i].modified_at = dt_format;
                    }
                }
                //--------------------------
            });
        },
        //====================================================
        showTrapLogs(trap_id, trap_name) {
            this.modalTitleTrap = trap_name;
            this.$refs["trap-log-modal"].show();
            this.axiosGetTrapLogs(trap_id);
        },
        //====================================================
        showRepeaterLogs(trap_id, trap_name) {
            this.modalTitleTrap = trap_name;
            this.$refs["trap-log-modal"].show();
            this.axiosGetTrapLogs(trap_id);
        },
        //====================================================
        showGatewayLogs(gateway_id, gateway_name) {
            this.modalTitleGateway = gateway_name;
            this.$refs["gateway-log-modal"].show();
            this.axiosGetGatewayLogs(gateway_id);
        },
        //====================================================
        // マップセンター
        //====================================================
        async setLatLng(lat, lng, name) {
            this.modalTitleMap = name;
            this.$refs["modalMapView"].show();
            await new Promise(r => setTimeout(r, 100));

            this.center.lat = lat;
            this.center.lng = lng;
            this.zoom = 14;
            const map = this.$refs["mapView"].mapObject;
            map.invalidateSize();
        },
        //====================================================
        aliveLevel(alived_at) {
            let diff = this.$moment().diff(this.$moment(alived_at), "days");
            return 3 - diff;
        },
        //====================================================
        // CSV ダウンロード
        //====================================================
        downloadTrapLogsCSV() {
            //----------------------------------------------------------------
            let mergeAry = [];
            mergeAry.push(["通信日時", "子機LoRa", "イベント", "バッテリ電圧", "親機LoRa", "無線電波強度", "環境ノイズ", "GPS緯度", "GPS経度", "タイムコード", "data"]);
            //----------------------------------------------------------------
            for (let obj of this.dbTrapLogs) {
                mergeAry.push([obj.modified_at, obj.lora_serial, obj.triggered, obj.battery_volt, obj.gw_lora_serial, obj.trap_rssi, obj.ra_rssi, obj.gps_latitude, obj.gps_longitude, obj.time_code, obj.raw_data]);
            }
            //----------------------------------------------------------------
            //  配列をCSV化
            //----------------------------------------------------------------
            let csv = "\ufeff" + "\n";
            for (let i in mergeAry) {
                csv += mergeAry[i].join(",") + "\n";
            }
            let blob = new Blob([csv], { type: "text/csv" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "hokapato-trap-logs.csv";
            document.body.appendChild(link);
            link.click();
        },
        //====================================================
        downloadGatewayLogsCSV() {
            //----------------------------------------------------------------
            let mergeAry = [];
            mergeAry.push(["通信日時", "親機LoRa", "バッテリ電圧", "モバイル感度", "GPS緯度", "GPS経度"]);
            //----------------------------------------------------------------
            for (let obj of this.dbGatewayLogs) {
                mergeAry.push([obj.modified_at, obj.lora_serial, obj.battery, obj.lte_rssi, obj.gps_latitude, obj.gps_longitude]);
            }
            //----------------------------------------------------------------
            //  配列をCSV化
            //----------------------------------------------------------------
            let csv = "\ufeff" + "\n";
            for (let i in mergeAry) {
                csv += mergeAry[i].join(",") + "\n";
            }
            let blob = new Blob([csv], { type: "text/csv" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "hokapato-gateway-logs.csv";
            document.body.appendChild(link);
            link.click();
        }
        //====================================================
    }
};
</script>
