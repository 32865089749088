<template>
    <div id="app">
        <common-header v-show="false"></common-header>
        <router-view></router-view>
        <common-footer v-show="true"></common-footer>
    </div>
</template>

<style lang="less">
html {
    touch-action: manipulation;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
/*-------------------------*/
.nav-item {
    font-size: 1.8rem;
    font-weight: bold;
}
/*-------------------------*/
.base-color {
    display: table;
    width: 100vw;
    height: 100vh;
    /* background: burlywood; */
    /* background: radial-gradient(wheat, burlywood); */
    background: white;
    padding-top: 0px;
    padding-bottom: 0px;
}
/*-------------------------*/
table.b-table > thead > tr > th {
    white-space: nowrap;
    vertical-align: middle;
    padding: 5px;
}
table.b-table > tbody > tr > td {
    vertical-align: middle;
    padding: 1px;
}
/*-------------------------*/
</style>

<script>
import CommonHeader from "@/components/CommonHeader";
import CommonFooter from "@/components/CommonFooter";
export default {
    components: {
        CommonHeader,
        CommonFooter
    }
};
</script>
