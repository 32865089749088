<template>
    <div class="CommonFooter">
        <b-navbar toggleable type="dark" variant="dark" fixed="bottom">
            <!--
            <router-link to="/shot" class="text-white">
                <h2><b-icon icon="camera" class="mx-3"></b-icon></h2>
            </router-link>
            <router-link to="/timeline" class="text-white">
                <h2><b-icon icon="credit-card2-front" class="mx-3"></b-icon></h2>
            </router-link>
            <router-link to="/map" class="text-white">
                <h2><b-icon icon="globe2" class="mx-3"></b-icon></h2>
            </router-link>
            <router-link to="/trap-map" class="text-white">
                <h2><b-icon icon="gear-wide-connected" class="mx-3"></b-icon></h2>
            </router-link>
            -->
            <router-link to="/home" class="text-white">
                <h2><b-icon icon="justify" class="mx-3"></b-icon></h2>
            </router-link>
            <!--
            <router-link to="/notify-config" class="text-white">
                <h2><b-icon icon="gear-fill" class="mx-3"></b-icon></h2>
            </router-link>
            -->
            <router-link to="/user" class="text-white">
                <h5 class="text-white">{{ myInfo.name }}</h5>
            </router-link>
        </b-navbar>
    </div>
</template>

<script>
import JWT from "jsonwebtoken";
export default {
    props: {
        //deviceId: Number
        //device_id: Number
    },
    //====================================================
    data() {
        return {
            jwt: "",
            myInfo: { id: 0, site_id: 0, name: "" },
            device_id: 100
        };
    },
    //====================================================
    mounted() {},
    //====================================================
    watch: {},
    //====================================================
    created: async function() {
        //this.jwt = this.$localStorage.get("user-jwt");
        //if (!this.jwt) {
        //    //this.$router.push({ name: "Logout" });
        //    return false;
        //}
        //this.myInfo = JWT.decode(this.jwt);
        //if (this.myInfo.exp < this.$moment().unix()) {
        //    //this.$router.push({ name: "Logout" });
        //    return false;
        //}

        //this.reloadJwt();
        setInterval(this.reloadJwt.bind(this), 1000);
    },
    //====================================================
    methods: {
        reloadJwt() {
            this.myInfo = { id: 0, site_id: 0, name: "" };
            this.jwt = this.$localStorage.get("user-jwt");
            if (!this.jwt) {
                return false;
            }
            this.myInfo = JWT.decode(this.jwt);
            if (this.myInfo.exp < this.$moment().unix()) {
                return false;
            }
            this.myInfo.countdown = this.$moment.unix(this.myInfo.exp).diff(this.$moment(), "seconds");
        }
    }
    //====================================================
};
</script>
