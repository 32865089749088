import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import Home from "../views/Home.vue";
import NotifyConfig from "../views/NotifyConfig.vue";
import NotifiedList from "../views/NotifiedList.vue";
import Shot from "../views/Shot.vue";
import HuntShot from "../views/HuntShot.vue";
import HokapatoList from "../views/HokapatoList.vue";
import Map from "../views/Map.vue";
//import TrapMap from "../views/TrapMap.vue";
import HuntMap from "../views/HuntMap.vue";
import Timeline from "../views/Timeline.vue";
import HuntTimeline from "../views/HuntTimeline.vue";
import User from "../views/User.vue";

Vue.use(VueRouter);

const routes = [
    {
        name: "Login",
        path: "/login",
        meta: { isPublic: true },
        component: Login
    },
    {
        name: "Logout",
        path: "/logout",
        meta: { isPublic: true },
        component: Logout
    },
    {
        path: "/",
        redirect: "/home"
    },
    {
        path: "/shot",
        name: "Shot",
        component: Shot
    },
    {
        path: "/user",
        name: "User",
        component: User
    },
    {
        path: "/hunt-shot",
        name: "HuntShot",
        component: HuntShot
    },
    {
        path: "/hokapato-list",
        name: "HokapatoList",
        component: HokapatoList
    },
    {
        path: "/home",
        name: "Home",
        component: Home
    },
    {
        path: "/notified-list",
        name: "NotifiedList",
        component: NotifiedList
    },
    {
        path: "/notify-config",
        name: "NotifyConfig",
        component: NotifyConfig
    },
    {
        path: "/map",
        name: "Map",
        component: Map
    },
    // {
    //     path: "/trap-map",
    //     name: "TrapMap",
    //     component: TrapMap
    // },
    {
        path: "/hunt-map",
        name: "HuntMap",
        component: HuntMap
    },
    {
        path: "/timeline",
        name: "Timeline",
        component: Timeline
    },
    {
        path: "/hunt-timeline",
        name: "HuntTimeline",
        component: HuntTimeline
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
    }
];

const router = new VueRouter({
    routes
});

export default router;
