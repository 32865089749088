<template>
    <div class="CommonHeader">
        <b-navbar toggleable="lg" type="dark" variant="dark" fixed="top">
            <b-button v-b-toggle.sidebar-1 class="p-0 mr-3">
                <b-icon icon="justify" class="mx-3"></b-icon>
            </b-button>
            <router-link to="/" class="text-white">
                <b-navbar-brand class="font-weight-bold">けものおと♪</b-navbar-brand>
            </router-link>
        </b-navbar>
        <b-sidebar id="sidebar-1" title="メニュー" backdrop shadow width="180px" bg-variant="dark" text-variant="light">
            <div class="p-1">
                <router-link to="/">
                    <b-button block variant="success">
                        <span class="text-white font-weight-bold">ホーム</span>
                    </b-button>
                </router-link>
            </div>
            <div class="p-1">
                <router-link to="/shot">
                    <b-button block variant="success">
                        <span class="text-white font-weight-bold">クイック投稿</span>
                    </b-button>
                </router-link>
            </div>
            <div class="p-1">
                <router-link to="/map">
                    <b-button block variant="success">
                        <span class="text-white font-weight-bold">マップ投稿</span>
                    </b-button>
                </router-link>
            </div>
            <div class="p-1">
                <router-link to="/logout">
                    <b-button block variant="danger">
                        <span class="text-white font-weight-bold"> Logout </span>
                    </b-button>
                </router-link>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import JWT from "jsonwebtoken";
export default {
    //===========================================================================
    props: {
        //deviceId: Number
        //device_id: Number
    },
    //===========================================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            intervalId: undefined,
            device_id: 100
        };
    },
    //===========================================================================
    beforeDestroy: function() {
        self.console.log("beforeDestory ---> clearInterval");
        if (this.intervalId) clearInterval(this.intervalId);
    },
    //===========================================================================
    mounted: function() {
        let interSelf = this;
        this.intervalId = setInterval(function() {
            const jwt = interSelf.$localStorage.get("user-jwt");
            if (jwt) {
                const myInfo = JWT.decode(jwt);
                if (myInfo.exp < interSelf.$moment().unix()) {
                    self.console.log("Timeout ---> Login Redirect!");
                    interSelf.$router.push({ name: "Logout" });
                }
            } else {
                self.console.log("NO JWT ---> Login Redirect!");
                interSelf.$router.push({ name: "Logout" });
            }
        }, 1000 * 60);
    },
    //===========================================================================
    watch: {},
    //===========================================================================
    created: function() {
        this.jwt = this.$localStorage.get("user-jwt");
        this.myInfo = JWT.decode(this.jwt);
    }
    //===========================================================================
};
</script>
