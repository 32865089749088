<template>
    <div class="hunt-shot">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <b-container fluid class="base-color">
            <b-card no-body class="m-1">
                <b-tabs card justified>
                    <!-- ================= -->
                    <b-tab title="写真">
                        <div class="mt-3">
                            <b-form>
                                <b-row>
                                    <b-col class="text-center" v-show="dispCameraBtn">
                                        <label>
                                            <b-icon icon="camera" style="width: 130px; border-radius: 1rem;" font-scale="4" class="bg-success p-1" variant="light"></b-icon>
                                            <b-form-file ref="camera" accept="image/*" capture @change="onFileChange" plain style="display: none;"></b-form-file>
                                        </label>
                                    </b-col>
                                    <b-col class="text-center">
                                        <label>
                                            <b-icon icon="image" style="width: 130px; border-radius: 1rem;" font-scale="4" class="bg-success p-1" variant="light"></b-icon>
                                            <b-form-file accept="image/*" @change="onFileChange" plain style="display: none;"></b-form-file>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-form>
                            <div class="m-0">
                                <!-- <b-card class="round-card"></b-card>
                                -->
                                <b-card no-body>
                                    <b-aspect :aspect="aspect" style="height: 300px;">
                                        <b-img fluid :src="uploadedImage" style="height: 300px;" />
                                    </b-aspect>
                                </b-card>
                            </div>
                        </div>
                        <!-- ---------------------------- -->
                        <div class="mt-5 pt-5"></div>
                        <!-- ---------------------------- -->
                        <div class="mt-3">
                            <b-form>
                                <b-row>
                                    <b-col class="text-center" v-show="dispCameraBtn">
                                        <label>
                                            <b-icon icon="camera" style="width: 130px; border-radius: 1rem;" font-scale="4" class="bg-success p-1" variant="light"></b-icon>
                                            <b-form-file ref="camera" accept="image/*" capture @change="onFileChange2nd" plain style="display: none;"></b-form-file>
                                        </label>
                                    </b-col>
                                    <b-col class="text-center">
                                        <label>
                                            <b-icon icon="image" style="width: 130px; border-radius: 1rem;" font-scale="4" class="bg-success p-1" variant="light"></b-icon>
                                            <b-form-file accept="image/*" @change="onFileChange2nd" plain style="display: none;"></b-form-file>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-form>
                            <div class="m-0">
                                <!-- <b-card class="round-card"></b-card>
                                -->
                                <b-card no-body>
                                    <b-aspect :aspect="aspect" style="height: 300px;">
                                        <b-img fluid :src="uploadedImage2nd" style="height: 300px;" />
                                    </b-aspect>
                                </b-card>
                            </div>
                        </div>
                        <!-- ---------------------------- -->
                        <div class="mt-5 pt-5"></div>
                        <!-- ---------------------------- -->
                        <div class="mt-3">
                            <b-form>
                                <b-row>
                                    <b-col class="text-center" v-show="dispCameraBtn">
                                        <label>
                                            <b-icon icon="camera" style="width: 130px; border-radius: 1rem;" font-scale="4" class="bg-success p-1" variant="light"></b-icon>
                                            <b-form-file ref="camera" accept="image/*" capture @change="onFileChange3rd" plain style="display: none;"></b-form-file>
                                        </label>
                                    </b-col>
                                    <b-col class="text-center">
                                        <label>
                                            <b-icon icon="image" style="width: 130px; border-radius: 1rem;" font-scale="4" class="bg-success p-1" variant="light"></b-icon>
                                            <b-form-file accept="image/*" @change="onFileChange3rd" plain style="display: none;"></b-form-file>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-form>
                            <div class="m-0">
                                <!-- <b-card class="round-card"></b-card>
                                -->
                                <b-card no-body>
                                    <b-aspect :aspect="aspect" style="height: 300px;">
                                        <b-img fluid :src="uploadedImage3rd" style="height: 300px;" />
                                    </b-aspect>
                                </b-card>
                            </div>
                        </div>
                        <!-- ---------------------------- -->
                        <div class="mt-5 pt-5"></div>
                        <!-- ---------------------------- -->
                        <div class="mt-3">
                            <b-form>
                                <b-row>
                                    <b-col class="text-center" v-show="dispCameraBtn">
                                        <label>
                                            <b-icon icon="camera" style="width: 130px; border-radius: 1rem;" font-scale="4" class="bg-success p-1" variant="light"></b-icon>
                                            <b-form-file ref="camera" accept="image/*" capture @change="onFileChange4th" plain style="display: none;"></b-form-file>
                                        </label>
                                    </b-col>
                                    <b-col class="text-center">
                                        <label>
                                            <b-icon icon="image" style="width: 130px; border-radius: 1rem;" font-scale="4" class="bg-success p-1" variant="light"></b-icon>
                                            <b-form-file accept="image/*" @change="onFileChange4th" plain style="display: none;"></b-form-file>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-form>
                            <div class="m-0">
                                <!-- <b-card class="round-card"></b-card>
                                -->
                                <b-card no-body>
                                    <b-aspect :aspect="aspect" style="height: 300px;">
                                        <b-img fluid :src="uploadedImage4th" style="height: 300px;" />
                                    </b-aspect>
                                </b-card>
                            </div>
                        </div>
                    </b-tab>
                    <!-- ================= -->
                    <b-tab title="場所" @click="mapInvalidateSize()">
                        <l-map ref="map" style="height: 400px;" :zoom="zoom" :minZoom="minZoom" :center="[newMapPoint.lat, newMapPoint.lng]" @click="handleMapClick" @update:zoom="zoomUpdated" @update:center="centerUpdated">
                            <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
                            <l-control-layers position="topright"></l-control-layers>
                            <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.name" :name="tileProvider.name" :visible="tileProvider.visible" :url="tileProvider.url" layer-type="base" :attribution="tileProvider.attribution" />
                            <l-marker :lat-lng="[newMapPoint.lat, newMapPoint.lng]" :zIndexOffset="200"></l-marker>
                            <l-marker v-for="p in mapTraps" :key="'P' + p.id" :lat-lng="[p.lat, p.lng]" :icon="p.icon" :options="p.markerOptions" :zIndexOffset="p.zIndexOffset"></l-marker>
                        </l-map>
                        <div>
                            <div v-show="newMapPoint.lat && newMapPoint.lng">
                                <h6 class="text-center">GPS：{{ newMapPoint.lat }}, {{ newMapPoint.lng }}</h6>
                            </div>
                            <div v-show="!newMapPoint.lat || !newMapPoint.lng">
                                <h6 class="text-center text-danger">位置情報へのアクセスを許可して下さい</h6>
                            </div>
                        </div>
                    </b-tab>
                    <!-- ================= -->
                    <b-tab title="詳細" @click="submitEnabled = newMapPoint.lat && newMapPoint.lng ? true : false">
                        <b-card no-body class="mb-2 p-2">
                            <b-form class="text-left font-weight-bold">
                                <b-form-group>
                                    <b-form-select v-model="newMapPoint.point_type" :options="pointTypeOptions" :state="newMapPoint.point_type ? true : false" size="lg"></b-form-select>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="獣種">
                                        <b-form-select v-model="newMapPoint.species" :options="speciesOptions" :state="true" size="lg"></b-form-select>
                                    </b-input-group>
                                </b-form-group>

                                <b-form-group>
                                    <b-input-group prepend="頭数">
                                        <b-form-select v-model="newMapPoint.headcount" :options="headcountOptions" :state="true" size="lg"></b-form-select>
                                    </b-input-group>
                                </b-form-group>

                                <b-form-group>
                                    <b-input-group prepend="性別">
                                        <b-form-select v-model="newMapPoint.gender" :options="genderOptions" :state="true" size="lg"></b-form-select>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="成/幼">
                                        <b-form-select v-model="newMapPoint.age" :options="ageOptions" :state="true" size="lg"></b-form-select>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="体長" append="cm">
                                        <b-form-input v-model="newMapPoint.body_length" :state="true" size="lg" type="number"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="体高" append="cm">
                                        <b-form-input v-model="newMapPoint.body_height" :state="true" size="lg" type="number"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="体重" append="kg">
                                        <b-form-input v-model="newMapPoint.body_weight" :state="true" size="lg" type="number"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="個体番号">
                                        <b-form-input v-model="newMapPoint.lot_no" :state="true" size="lg"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="罠種">
                                        <b-form-select v-model="newMapPoint.trap_type" :options="trapTypeOptions" :state="true" size="lg"></b-form-select>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="処理">
                                        <b-form-select v-model="newMapPoint.disposal" :options="disposalOptions" :state="true" size="lg"></b-form-select>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="地区">
                                        <b-form-select v-model="newMapPoint.village" :options="villagesOptions" :state="true" size="lg"></b-form-select>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="旧番号">
                                        <b-form-input v-model="newMapPoint.old_no" :state="true" size="lg" maxlength="20"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="許可番号">
                                        <b-form-input v-model="newMapPoint.license_no" :state="true" size="lg" maxlength="20"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="捕獲場所">
                                        <b-form-input v-model="newMapPoint.hunting_location" :state="true" size="lg"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="確認方法">
                                        <b-form-input v-model="newMapPoint.confirmation_method" :state="true" size="lg"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="確認者">
                                        <b-form-input v-model="newMapPoint.verifier_name" :state="true" size="lg"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="尾確認者">
                                        <b-form-input v-model="newMapPoint.tail_verifier_name" :state="true" size="lg"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="尾確認日">
                                        <b-form-input v-model="newMapPoint.tail_verification_at" :state="true" size="lg" placeholder="YYYY-MM-DD"></b-form-input>
                                        <b-form-group-append>
                                            <b-form-datepicker v-model="newMapPoint.tail_verification_at" button-only right locale="ja" size="lg" :max="$moment().format('YYYY-MM-DD')"></b-form-datepicker>
                                        </b-form-group-append>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group>
                                    <b-input-group prepend="メモ">
                                        <b-form-input v-model="newMapPoint.title" size="lg"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-form>
                        </b-card>
                    </b-tab>
                </b-tabs>
            </b-card>
            <div class="mt-3">
                <b-button block :disabled="!submitEnabled" size="lg" variant="warning" @click="confirmModalPoint()" class="p-3 font-weight-bold" style="border-radius: 1rem;">報告する</b-button>
            </div>
            <div class="p-5">&nbsp;</div>
        </b-container>
        <!-- =============================================== -->
        <b-modal ref="modal-box" title="確認" centered no-close-on-backdrop no-close-on-esc header-bg-variant="primary" header-text-variant="light" @ok="submitModalPoint()">
            <h5>報告前に再度写真を確認ください。</h5>
            <h6>・頭の向き</h6>
            <h6>・胴体の番号</h6>
            <h6>・腕章</h6>
            <h6>・捕獲許可証</h6>
        </b-modal>
        <!-- =============================================== -->
    </div>
</template>

<style></style>

<script>
import JWT from "jsonwebtoken";
//------------------------------------------
// リーフレットJS
//------------------------------------------
import { LMap, LTileLayer, LControlLayers, LControlScale, LMarker } from "vue2-leaflet";
//------------------------------------------
export default {
    name: "HuntShot",
    components: {
        LMap,
        LTileLayer,
        LControlLayers,
        LControlScale,
        LMarker
    },
    //========================================================
    data() {
        return {
            //----------
            jwt: "",
            myInfo: [],
            dbSite: [],
            dbUser: {},
            dbSpecies: [],
            dbHeadcounts: [],
            dbVillages: [],
            dbTraps: [],
            allowTraps: [],
            dbTrapTYpes: [],
            dbDisposals: [],
            mapTraps: [],
            //---------------------
            dispCameraBtn: true,
            //---------------------
            submitEnabled: false,
            //---------------------
            center: { lat: 0, lng: 0 },
            zoom: 12,
            minZoom: 6,
            //---------------------
            imgTrapGreen: require("@/assets/map-icon/trap-green.png"),
            imgTrapYellow: require("@/assets/map-icon/trap-yellow.png"),
            imgTrapRed: require("@/assets/map-icon/trap-red.png"),
            imgTrapGray: require("@/assets/map-icon/trap-gray.png"),
            //---------------------
            imgRepeaterGreen: require("@/assets/map-icon/repeater-green.png"),
            imgRepeaterYellow: require("@/assets/map-icon/repeater-yellow.png"),
            imgRepeaterRed: require("@/assets/map-icon/repeater-red.png"),
            imgRepeaterGray: require("@/assets/map-icon/repeater-gray.png"),
            //---------------------
            imgGatewayGreen: require("@/assets/map-icon/gateway-green.png"),
            imgGatewayYellow: require("@/assets/map-icon/gateway-yellow.png"),
            imgGatewayRed: require("@/assets/map-icon/gateway-red.png"),
            imgGatewayGray: require("@/assets/map-icon/gateway-gray.png"),
            //---------------------
            tileProviders: [
                {
                    name: "OpenStreet",
                    visible: false,
                    attribution: '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>',
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                },
                {
                    name: "標準地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
                },
                {
                    name: "衛星写真",
                    visible: true,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg"
                }
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
                // },
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                // }
            ],
            //---------------------
            uploadedImage: null,
            uploadedImage2nd: null,
            uploadedImage3rd: null,
            uploadedImage4th: null,
            url: "",
            //---------------------
            pointTypeOptions: [{ text: "捕獲", value: "捕獲" }],
            villagesOptions: [
                { text: "---", value: "---" }
                // { text: "A地区", value: "A地区" },
                // { text: "B地区", value: "B地区" },
                // { text: "その他", value: "その他" }
            ],
            speciesOptions: [
                { text: "---", value: "---" }
                // { text: "イノシシ", value: "イノシシ" },
                // { text: "シカ", value: "シカ" },
                // { text: "その他", value: "その他" },
                // { text: "不明", value: "不明" }
            ],
            headcountOptions: [
                { text: "1頭", value: "1頭" },
                { text: "2頭", value: "2頭" }
            ],
            genderOptions: [
                { text: "", value: "" },
                { text: "オス", value: "オス" },
                { text: "メス", value: "メス" },
                { text: "不明", value: "不明" }
            ],
            ageOptions: [
                { text: "", value: "" },
                { text: "成獣", value: "成獣" },
                { text: "幼獣", value: "幼獣" }
            ],
            trapTypeOptions: [
                { text: "くくり罠", value: "くくり罠" },
                { text: "箱罠", value: "箱罠" },
                { text: "囲い罠", value: "囲い罠" }
            ],
            disposalOptions: [
                { text: "埋設", value: "埋設" },
                { text: "真首埋設", value: "真首埋設" },
                { text: "その他", value: "その他" }
            ],
            //---------------------
            newMapPoint: {
                title: "",
                jpg_file: null,
                jpg_file_2nd: null,
                jpg_file_3rd: null,
                jpg_file_4th: null,
                point_type: "捕獲",
                lat: 0,
                lng: 0,
                species: "",
                headcount: "",
                gender: "",
                age: "",
                trap_type: "",
                disposal: "",
                body_length: "",
                body_height: "",
                body_weight: "",
                lot_no: "",
                village: "",
                old_no: "",
                license_no: "",
                hunting_location: "",
                confirmation_method: "",
                verifier_name: "",
                tail_verifier_name: "",
                tail_verification_at: ""
            },
            //---------------------
            aspect: "16:9",
            isLoading: false
            //---------------------
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("user-jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        //---------------------------------
        if (this.$localStorage.get("browser-option")) {
            let opt = JSON.parse(this.$localStorage.get("browser-option"));
            if (opt.camera == "off") this.dispCameraBtn = false;
        }
        //---------------------------------
        this.isLoading = true;
        await this.axiosGetSites();
        await this.axiosGetUsers();
        await this.axiosGetUserConfigs();
        await this.axiosGetSpecies();
        await this.axiosGetHeadcounts();
        await this.axiosGetVillages();
        await this.axiosGetTrapTypes();
        await this.axiosGetDisposals();
        await this.axiosGetTraps();
        this.isLoading = false;
        //---------------------------------
        window.scrollTo(0, 0);
        //---------------------------------
    },
    //========================================================
    computed: {},
    //========================================================
    watch: {},
    //========================================================
    mounted: function() {
        //-------------------------------
        // 現在位置を取得
        //-------------------------------
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function(position) {
                    let coords = position.coords;
                    // 緯度経度だけ取得
                    this.newMapPoint.lat = Math.floor(coords.latitude * 1000000) / 1000000;
                    this.newMapPoint.lng = Math.floor(coords.longitude * 1000000) / 1000000;
                    this.zoom = 18;
                }.bind(this),
                function(error) {
                    self.console.log(error);
                }
            );
        }
        //-------------------------------
    },
    //====================================================
    beforeDestroy: function() {},
    //====================================================
    methods: {
        zoomUpdated: function() {
            this.dispTrapPinMap();
        },
        centerUpdated: function() {
            this.dispTrapPinMap();
        },
        //====================================================
        // 写真撮影されたら、その画像を表示
        //====================================================
        onFileChange(e) {
            const files = e.target.files || e.dataTransfer.files;
            this.uploadedImage = URL.createObjectURL(files[0]);
            this.newMapPoint.jpg_file = files[0];
            this.submitEnabled = true;
        },
        onFileChange2nd(e) {
            const files = e.target.files || e.dataTransfer.files;
            this.uploadedImage2nd = URL.createObjectURL(files[0]);
            this.newMapPoint.jpg_file_2nd = files[0];
        },
        onFileChange3rd(e) {
            const files = e.target.files || e.dataTransfer.files;
            this.uploadedImage3rd = URL.createObjectURL(files[0]);
            this.newMapPoint.jpg_file_3rd = files[0];
        },
        onFileChange4th(e) {
            const files = e.target.files || e.dataTransfer.files;
            this.uploadedImage4th = URL.createObjectURL(files[0]);
            this.newMapPoint.jpg_file_4th = files[0];
        },
        //====================================================
        // Mapクリックで、GPS座標を補正できる
        //====================================================
        handleMapClick: function(event) {
            this.newMapPoint.lat = Math.floor(event.latlng.lat * 1000000) / 1000000;
            this.newMapPoint.lng = Math.floor(event.latlng.lng * 1000000) / 1000000;
        },
        //====================================================
        // Map領域の調整は、表示されてから
        //====================================================
        async mapInvalidateSize() {
            await new Promise(r => setTimeout(r, 100));
            const map = this.$refs.map.mapObject;
            map.invalidateSize();
        },
        //====================================================

        //====================================================
        async axiosGetSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) this.dbSite = response.data.json[0];
            });
        },
        //====================================================
        async axiosGetUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                params: {
                    _fields: "branch_id"
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbUser = response.data.json[0];
                }
            });
        },
        //====================================================
        async axiosGetSpecies() {
            await this.axios({
                method: "GET",
                url: "/web/api/species",
                params: { "_order[sort_no]": "asc", _fields: "name" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbSpecies = response.data.json;
                this.speciesOptions = [];
                this.speciesOptions.push({ text: "", value: "" });
                for (let species of this.dbSpecies) {
                    this.speciesOptions.push({ text: species.name, value: species.name });
                }
            });
        },
        //====================================================
        async axiosGetHeadcounts() {
            await this.axios({
                method: "GET",
                url: "/web/api/headcounts",
                params: { "_order[sort_no]": "asc", _fields: "name" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbHeadcounts = response.data.json;
                this.headcountOptions = [];
                this.headcountOptions.push({ text: "", value: "" });
                for (let headcount of this.dbHeadcounts) {
                    this.headcountOptions.push({ text: headcount.name, value: headcount.name });
                }
            });
        },
        //====================================================
        async axiosGetTrapTypes() {
            await this.axios({
                method: "GET",
                url: "/web/api/trap_types",
                params: { "_order[sort_no]": "asc", _fields: "name" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbTrapTypes = response.data.json;
                this.trapTypeOptions = [];
                this.trapTypeOptions.push({ text: "", value: "" });
                for (let rec of this.dbTrapTypes) {
                    this.trapTypeOptions.push({ text: rec.name, value: rec.name });
                }
            });
        },
        //====================================================
        async axiosGetDisposals() {
            await this.axios({
                method: "GET",
                url: "/web/api/disposals",
                params: { "_order[sort_no]": "asc", _fields: "name" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbDisposals = response.data.json;
                this.disposalOptions = [];
                this.disposalOptions.push({ text: "", value: "" });
                for (let rec of this.dbDisposals) {
                    this.disposalOptions.push({ text: rec.name, value: rec.name });
                }
            });
        },
        //====================================================
        async axiosGetVillages() {
            await this.axios({
                method: "GET",
                url: "/web/api/villages",
                params: { "_order[sort_no]": "asc", _fields: "name" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbVillages = response.data.json;
                this.villagesOptions = [];
                this.villagesOptions.push({ text: "", value: "" });
                for (let village of this.dbVillages) {
                    this.villagesOptions.push({ text: village.name, value: village.name });
                }
            });
        },
        //====================================================
        async axiosGetUserConfigs() {
            await this.axios({
                method: "GET",
                url: "/web/api/user_configs",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.allowTraps = [];
                if (response.data.json) {
                    this.dbUserConfigs = response.data.json;
                    for (let i in this.dbUserConfigs) {
                        if (this.dbUserConfigs[i].allow_view == "1") {
                            this.allowTraps.push(this.dbUserConfigs[i].trap_id);
                        }
                    }
                }
            });
        },
        //====================================================
        // ほかパト
        //====================================================
        async axiosGetTraps() {
            //--------------------------------
            // 今日以外は、「ほかパト」の位置マーカーを表示しない。
            let diffDay = this.$moment(this.targetDate).diff(this.$moment(), "days");
            if (diffDay < 0) {
                this.dbTraps = [];
                return false;
            }
            //--------------------------------
            const params = {
                is_active: 1,
                "id[IN]": this.allowTraps.join(","),
                "_order[id]": "asc"
            };
            await this.axios({
                method: "GET",
                url: "/web/api/traps",
                params: params,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbTraps = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbTraps) {
                    let alived_at = this.dbTraps[i].alived_at;
                    if (alived_at) {
                        let alived_at_format = this.$moment(alived_at).format("YYYY-MM-DD HH:mm");
                        this.dbTraps[i].alived_at = alived_at_format;
                    }
                }
                //--------------------------
            });
        },
        //====================================================

        //====================================================
        // ほかパト：マーカー
        //====================================================
        dispTrapPinMap() {
            //--------------------------
            // ピンマーカーデータ
            //--------------------------
            this.mapTraps = []; //クリア
            //--------------------------
            for (let idx in this.dbTraps) {
                let rep = JSON.parse(JSON.stringify(this.dbTraps[idx]));
                let id = rep.id;
                let lat = Number(rep.gps_latitude);
                let lng = Number(rep.gps_longitude);
                let icon = null;
                //-------------------------------------
                icon = self.L.icon({ iconUrl: this.imgTrapGreen, iconSize: [25, 25] });
                if (rep.led_capture == "1") {
                    icon = self.L.icon({ iconUrl: this.imgTrapRed, iconSize: [25, 25] });
                }
                if (!rep.alived_at || this.$moment().diff(this.$moment(rep.alived_at), "days") >= 1) {
                    icon = self.L.icon({ iconUrl: this.imgTrapYellow, iconSize: [25, 25] });
                }
                if (!rep.alived_at || this.$moment().diff(this.$moment(rep.alived_at), "days") >= 3) {
                    icon = self.L.icon({ iconUrl: this.imgTrapGray, iconSize: [25, 25] });
                }
                //-------------------------------------
                if (icon) {
                    //-------------------------------------
                    // ピンマーカー用データ
                    //-------------------------------------
                    this.mapTraps.push({
                        id: id,
                        lat: lat,
                        lng: lng,
                        icon: icon,
                        markerOptions: { opacity: 1.0 },
                        popupOption: { minWidth: 50, offset: self.L.point([0, 3]) },
                        zIndexOffset: 100,
                        db: rep
                    });
                    //-------------------------------------
                }
            }
            //--------------------------
        },
        //====================================================
        //====================================================
        // マップポイント追加 (submit)
        //====================================================
        confirmModalPoint() {
            if (this.$moment(this.newMapPoint.tail_verification_at) > this.$moment()) {
                alert("未来の日付で投稿はできません。");
                return false;
            }
            if (this.newMapPoint.tail_verification_at && !this.$moment(this.newMapPoint.tail_verification_at, "YYYY-MM-DD", true).isValid()) {
                alert("尾確認日が不正です。");
                return false;
            }
            this.$refs["modal-box"].show();
        },
        //====================================================
        submitModalPoint() {
            this.submitEnabled = false;
            //-----------------------------------
            //this.$refs["modalAddCircle"].hide(); // Modal Hide
            //-----------------------------------
            // const axiosData = {
            //     site_id: this.myInfo.site_id,
            //     user_id: this.newMapPoint.user_id,
            //     user_name: this.newMapPoint.user_name,
            //     posted_at: this.newMapPoint.posted_at,
            //     title: this.newMapPoint.title.trim(),
            //     jpg_file: this.newMapPoint.jpg_file,
            //     village: this.newMapPoint.village,
            //     gps_latitude: this.newMapPoint.lat,
            //     gps_longitude: this.newMapPoint.lng,
            //     point_type: this.newMapPoint.point_type,
            //     species: this.newMapPoint.species,
            //     gender: this.newMapPoint.gender,
            //     headcount: this.newMapPoint.headcount,
            //     is_public: this.newMapPoint.is_public
            // };
            //-----------------------------------
            let branch_id = 0;
            if (this.dbUser.branch_id) branch_id = this.dbUser.branch_id;

            let formData = new FormData();
            formData.append("site_id", this.myInfo.site_id);
            formData.append("user_id", this.myInfo.id);
            formData.append("branch_id", branch_id);
            formData.append("user_name", this.myInfo.name);
            formData.append("posted_at", this.$moment().format("YYYY-MM-DD HH:mm:ss"));
            formData.append("title", this.newMapPoint.title);
            formData.append("jpg_file", this.newMapPoint.jpg_file);
            formData.append("jpg_file_2nd", this.newMapPoint.jpg_file_2nd);
            formData.append("jpg_file_3rd", this.newMapPoint.jpg_file_3rd);
            formData.append("jpg_file_4th", this.newMapPoint.jpg_file_4th);
            formData.append("village", this.newMapPoint.village);
            formData.append("gps_latitude", this.newMapPoint.lat);
            formData.append("gps_longitude", this.newMapPoint.lng);
            formData.append("point_type", this.newMapPoint.point_type);
            formData.append("species", this.newMapPoint.species);
            formData.append("headcount", this.newMapPoint.headcount);
            formData.append("gender", this.newMapPoint.gender);
            formData.append("age", this.newMapPoint.age);
            formData.append("trap_type", this.newMapPoint.trap_type);
            formData.append("disposal", this.newMapPoint.disposal);
            formData.append("body_length", this.newMapPoint.body_length);
            formData.append("body_height", this.newMapPoint.body_height);
            formData.append("body_weight", this.newMapPoint.body_weight);
            formData.append("lot_no", this.newMapPoint.lot_no);
            formData.append("status", "public");
            formData.append("old_no", this.newMapPoint.old_no);
            formData.append("license_no", this.newMapPoint.license_no);
            formData.append("hunting_location", this.newMapPoint.hunting_location);
            formData.append("confirmation_method", this.newMapPoint.confirmation_method);
            formData.append("verifier_name", this.newMapPoint.verifier_name);
            formData.append("tail_verifier_name", this.newMapPoint.tail_verifier_name);
            formData.append("tail_verification_at", this.newMapPoint.tail_verification_at || "");
            //-----------------------------------
            let restMethod = "POST";
            let restUrl = "/web/api/map_hunt_points";
            if (this.newMapPoint.id > 0) {
                restMethod = "PUT";
                restUrl = "/web/api/map_hunt_points/" + this.newMapPoint.id;
            }
            this.isLoading = true;
            this.axios({
                method: restMethod,
                url: restUrl,
                headers: { Authorization: "Bearer " + this.jwt },
                data: formData,
                timeout: 30000
            }).then(response => {
                this.isLoading = false;
                this.submitEnabled = true;
                alert("送信しました。\nご投稿ありがとうございました。");
                //self.console.log(response.data);
                if (response.data.json[0]) {
                    const record = response.data.json[0];
                    this.$router.push({
                        name: "HuntTimeline",
                        query: {
                            pk: record.id,
                            lat: record.gps_latitude,
                            lng: record.gps_longitude
                        }
                    });
                }
            });
        }
        //====================================================
    }
    //====================================================
};
//----------------------------------------------------
// https://qiita.com/hikach/items/655729bc1893392dfd42
// https://qiita.com/sasao3/items/527c8d7bcf9e2f3c5843
//----------------------------------------------------
</script>
