/* ==================================================
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
================================================ */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";

import moment from "vue-moment";
import axios from "axios";
import VueLocalStorage from "vue-localstorage";
import VueAxios from "vue-axios";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

//------------------------------------------
// リーフレットJS
//------------------------------------------
import "leaflet/dist/leaflet.css";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    markerColor: "orange",
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
//------------------------------------------

Vue.use(VueLocalStorage);
Vue.use(moment);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueAxios, axios);

//axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "/" : "http://ise-labo.com";
//axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "/" : "http://ik1-436-50493.vs.sakura.ne.jp";
axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "/" : "http://localhost:8010";

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App)
}).$mount("#app");
